import i18n from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  DesktopChunkedCol,
  DesktopChunkedItemCol,
  DesktopTechnicalFieldsContainer,
  GenericTechnicalFieldNameText,
  MobileFieldContainer,
  MobileGroupItemCol,
  MobileTechnicalFieldsContainer,
  NoGroupFieldContainer,
  NoGroupTechnicalTitle,
  NonGroupedContainer,
  StyledTechicalField,
  TechnicalFieldNameText,
  TechnicalFieldsGroupedRow,
  TechnicalFieldsRowDesktop,
  TechnicalFieldsRowMobile,
  TechnicalFieldsText,
  TechnicalFieldsWrapper,
  TechnicalMobileFieldsText,
  TechnicalObjectArrayContainer,
  TechnicalTitleFieldContainer,
} from '../../../elements/fabrics/fabricStyledElement';
import { FabricCert } from '../../../utils/backendRequests/certifications/CertificationTypes';
import { formFieldsProp } from '../../../utils/fabrics/FabricsTypes';
import { priceParser } from '../../../utils/fabrics/parseFabric';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import {
  compositionsProp,
  parseTranslatedCompositions,
} from '../../../utils/parsers/parseTranslatedCompositions';
import { RootAuth } from '../../../utils/types/Types';
import { DisplayCertifications } from '../../certification/DisplayCertifications';
import {
  DetailsComponentProps,
  FabricTabProps,
  NonGroupDetailsComponentProps,
} from '../FabricTypes';

export const technicalFields = returnOrderedFields([
  'cuttable_width_cm',
  'coverage',
  'ml_pattern_tags',
  'colour_fastness',
  'country_of_origin',
  'compositions',
  'wash_care',
  'piling',
  'gauge_inch',
  'yarn_count',
  'special_yarn',
  'stretch',
  'hand_feel',
  'functional_finish',
  'season',
  'usage_category',
  'lace_structure',
  'pattern_design',
  'repeat',
  'aesthetic_finish',
  'edge_finish',
  'look',
  'sustainability',
  'availability',
  'stock_m',
  'stock_sqm',
  'stock_kg',
  'stock_piece',
  'certifications',
  'creation_date',
]);

const ITEMS_PER_COLUMN = 10; // Set a limit for how many items per column

export function FabricTechnicalTab({ fabric, isMobile }: FabricTabProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  const DetailsComponent = ({
    title,
    value,
    fieldName,
  }: DetailsComponentProps) => {
    let displayedValue = value;

    if (typeof value === 'number' && fieldName.startsWith('price')) {
      displayedValue = priceParser(fabric.currency as string, value);
    }

    if (fieldName === 'certifications') {
      return (
        <TechnicalObjectArrayContainer>
          <TechnicalFieldNameText>{title}:</TechnicalFieldNameText>
          <DisplayCertifications
            certArray={value as FabricCert[]}
            showDownload={true}
            isTechnical={true}
          />
        </TechnicalObjectArrayContainer>
      );
    } else if (fieldName === 'compositions') {
      const translatedComposition = parseTranslatedCompositions(
        value as compositionsProp[],
        false
      );
      return (
        <TechnicalObjectArrayContainer>
          <TechnicalFieldNameText>{title}:</TechnicalFieldNameText>
          <span>{translatedComposition}</span>
        </TechnicalObjectArrayContainer>
      );
    }

    return (
      <TechnicalTitleFieldContainer>
        <span>
          <GenericTechnicalFieldNameText>
            {title}:{' '}
          </GenericTechnicalFieldNameText>
          {String(displayedValue)}
        </span>
      </TechnicalTitleFieldContainer>
    );
  };

  const NonGroupDetailsComponent = ({
    title,
    value,
  }: NonGroupDetailsComponentProps) => {
    return (
      <StyledTechicalField>
        <NoGroupTechnicalTitle>{title}</NoGroupTechnicalTitle>
        <NoGroupFieldContainer>{String(value)}</NoGroupFieldContainer>
      </StyledTechicalField>
    );
  };

  const filteredTechnicalFields = technicalFields.filter(
    (item: formFieldsProp) => {
      const fieldValue = fabric[item.name as keyof typeof fabric];
      return (
        fieldValue !== null &&
        fieldValue !== '' &&
        (Array.isArray(fieldValue) ? fieldValue.length !== 0 : true)
      );
    }
  );

  const groupColumnsByType = (columns: formFieldsProp[]) => {
    return columns.reduce(
      (acc, column) => {
        const group = column.grouping as string;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(column);
        return acc;
      },
      {} as Record<string, formFieldsProp[]>
    );
  };

  const groupedColumns = groupColumnsByType(filteredTechnicalFields);

  const chunkArray = (arr: formFieldsProp[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const technicalFieldsGrouped = () => (
    <>
      {isMobile
        ? // For mobile, use a single column with all fields directly
          Object.entries(groupedColumns).map(([group, cols]) => (
            <React.Fragment key={group}>
              <MobileTechnicalFieldsContainer>
                <TechnicalMobileFieldsText>
                  {i18n.t(`headers:${group}`)}
                </TechnicalMobileFieldsText>
                <TechnicalFieldsRowMobile gutter={[16, 16]}>
                  {/* Remove height limit */}
                  <MobileGroupItemCol>
                    {/* Make sure it's full width */}
                    {cols.map((item) => {
                      const value = fabric[item.name as keyof typeof fabric];
                      return (
                        <MobileFieldContainer key={item.name}>
                          <DetailsComponent
                            title={parseFabricTitles(
                              item.name,
                              measurementUnit
                            )}
                            value={
                              value as string | FabricCert[] | number | null
                            }
                            fieldName={item.name}
                          />
                        </MobileFieldContainer>
                      );
                    })}
                  </MobileGroupItemCol>
                </TechnicalFieldsRowMobile>
              </MobileTechnicalFieldsContainer>
            </React.Fragment>
          ))
        : // For non-mobile, use chunked columns as before
          Object.entries(groupedColumns).map(([group, cols]) => {
            const chunkedColumns = chunkArray(cols, ITEMS_PER_COLUMN);

            return (
              <React.Fragment key={group}>
                <DesktopTechnicalFieldsContainer>
                  <TechnicalFieldsText>
                    {i18n.t(`headers:${group}`)}
                  </TechnicalFieldsText>
                  <TechnicalFieldsRowDesktop gutter={[16, 16]}>
                    {chunkedColumns.map((colGroup, index) => (
                      <DesktopChunkedCol key={index}>
                        {colGroup.map((item) => {
                          const value =
                            fabric[item.name as keyof typeof fabric];
                          return (
                            <DesktopChunkedItemCol key={item.name}>
                              <DetailsComponent
                                title={parseFabricTitles(
                                  item.name,
                                  measurementUnit
                                )}
                                value={
                                  value as string | FabricCert[] | number | null
                                }
                                fieldName={item.name}
                              />
                            </DesktopChunkedItemCol>
                          );
                        })}
                      </DesktopChunkedCol>
                    ))}
                  </TechnicalFieldsRowDesktop>
                </DesktopTechnicalFieldsContainer>
              </React.Fragment>
            );
          })}
    </>
  );

  return (
    <>
      <TechnicalFieldsGroupedRow $isMobile={isMobile} gutter={[16, 16]}>
        {technicalFieldsGrouped()}
      </TechnicalFieldsGroupedRow>
      <NonGroupedContainer $hasGroups={filteredTechnicalFields.length === 0}>
        {fabric.ml_description && (
          <TechnicalFieldsWrapper>
            <NonGroupDetailsComponent
              title={i18n.t('fabric_fields:ml_description')}
              value={fabric.ml_description}
            />
          </TechnicalFieldsWrapper>
        )}
        {fabric.description && (
          <TechnicalFieldsWrapper>
            <NonGroupDetailsComponent
              title={i18n.t('fabric_fields:description')}
              value={fabric.description}
            />
          </TechnicalFieldsWrapper>
        )}
        {fabric.notes && (
          <TechnicalFieldsWrapper>
            <NonGroupDetailsComponent
              title={i18n.t('fabric_fields:notes')}
              value={fabric.notes}
            />
          </TechnicalFieldsWrapper>
        )}
      </NonGroupedContainer>
    </>
  );
}
