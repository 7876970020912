import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrency } from '../../../utils/backendRequests/list/getCurrency';
import { RootState } from '../../../utils/types/Types';
import { PricesListProps } from '../../prices/PricesTypes';
import { TableGeneric } from '../TableGeneric';
import { columnFields } from '../TableTypes';
import { columnsDesktop } from './ColumnsDesktop';
import { columnsMobile } from './ColumnsMobile';

export function PricesList({
  data,
  tableState,
  setTableState,
  collectionId,
  designerId,
  getPricesList,
}: PricesListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const [currencyList, setCurrencyList] = useState<Record<string, string>>({});

  const getCurrencyOptions = async () => {
    const currencyData = await getCurrency();
    setCurrencyList(currencyData);
  };

  useEffect(() => {
    getCurrencyOptions();
  }, []);

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        columnsMobile(collectionId, designerId, getPricesList, currencyList)
      );
    } else {
      setColumns(
        columnsDesktop(collectionId, designerId, getPricesList, currencyList)
      );
    }
  }, [isMobileFlag, currencyList]);

  return (
    <>
      <TableGeneric
        columns={columns}
        data={data}
        isBackendPaginated={true}
        isBackendPagination={true}
        tableState={tableState}
        setTableState={setTableState}
      />
    </>
  );
}
