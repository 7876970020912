import styled from 'styled-components';

import { Button, Col, Divider, Input, List, Row, Space } from 'antd';
import { SelectWithSearch } from '../../components/search/SelectWithSearch';
import { Color } from '../base/Color';

// Styled for file upload/edit actions
export const FileActionCol = styled(Col)<{ $isMobileFlag: boolean }>`
  display: flex;
  margin-top: -5px;
  justify-content: center;
  align-items: center;
  margin-left: ${($isMobileFlag) => ($isMobileFlag ? '-200px' : '0')};
`;

export const FileNameCol = styled(Col)<{ $isMobileFlag: boolean }>`
  justify-content: center;
  align-items: center;
  margin-left: ${($isMobileFlag) => ($isMobileFlag ? '-10px' : '0')};
`;

export const FileText = styled.div`
  font-size: 14px;
  margin-top: 2px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Bulk upload rows
export const CreateBulkUploadRow = styled(Row)`
  margin-top: 20px;
`;

export const CreateLibraryRow = styled(Row)`
  margin-top: 10px;
`;

// Inspirations columns
export const CreateInspirationCol = styled(Col)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageUploadWrapper = styled.div`
  width: auto; /* Set desired width or auto */
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    /* Target the direct child of the wrapper, which should be the ImageUpload component */
    width: 50%; /* Adjust this percentage as needed */
  }
`;

// Create/Edit collection
export const CollectionModalContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2px;
`;

// Update Admin Brands
export const UpdateBrandImage = styled(Space)`
  width: 200px;
  margin: auto;
  display: block;
`;

// Select Fabric Fields Modal
export const SelectableFieldContainer = styled.div`
  padding: 6px;
  display: flex;
  align-items: center;
`;

export const SelectableMobileContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

export const MobileSelectableFieldContainer = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
`;

// Modal container for fabric fields checboxes
export const CollectionFieldsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SelectFieldsFieldsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ShareableLinksContainer = styled.div`
  max-height: 170px;
  overflow-y: auto;
`;

export const MessagesListItem = styled(List.Item)<{ $isSameBrand: boolean }>`
  // Overwrite antd list item border for messages
  border-block-end: none !important;
  justify-content: ${(props) =>
    props.$isSameBrand ? 'flex-end' : 'flex-start'} !important;
`;

export const MessageBlurbContainer = styled.div<{ $isSameBrand: boolean }>`
  align-self: ${(props) => (props.$isSameBrand ? 'flex-end' : 'flex-start')};
  background-color: ${(props) =>
    props.$isSameBrand ? Color.PrimaryMsg : Color.SecondaryMsg};
  color: ${(props) => (props.$isSameBrand ? Color.Black : Color.DullGrey)};
  border-radius: 10px;
  padding: 10px;
  max-width: 60%;
  margin-bottom: 10px;
  text-align: ${(props) => (props.$isSameBrand ? 'right' : 'left')};
`;

export const SendCollectionContainer = styled.div<{ $isMobileFlag: boolean }>`
  width: ${(props) => (props.$isMobileFlag ? '100%' : '550px')};
`;

export const EmailInputCol = styled(Col)`
  margin-top: -20px;
`;

export const EmailInput = styled(Input)`
  margin-top: -10px;
`;

export const BrandSelector = styled(SelectWithSearch)`
  margin-top: 20px;
  width: 90%;
`;

export const SharedDesignersContainer = styled(Space)`
  margin-top: 10px;
`;

export const DesignerBrandContainer = styled(Space)`
  height: 2rem;
`;

export const VerticalModalDivider = styled(Divider)`
  height: 100%;
`;

// Hangers Requests
export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const MessagesContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 10px;
  word-break: break-word;
  padding-right: 5px;
`;

export const ChatSendMessageContainer = styled(Input.Search)`
  width: 100%;
  margin-bottom: 10px;
`;

export const NewChatTextContainer = styled(Row)`
  padding-bottom: 50px;
  margin-top: 40px;
`;

export const RequesterUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const EditHangerRequestContainer = styled.div`
  height: 80px;
`;

export const EditStatusExplanationContent = styled(Row)`
  padding-bottom: 10px;
  margin-top: 5x;
`;

export const StatusSelector = styled(SelectWithSearch)`
  margin-top: 10px;
  width: 100%;
`;

export const ScrollableListContainer = styled.div`
  max-height: 400px;
  overflow: auto;
`;

export const FormModalFooter = styled.div`
  position: absolute;
  bottom: -20px;
  right: -50px;
`;

export const DisplayPDFContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
  position: relative;
`;

export const PDFToolbarContainer = styled(Row)<{ $isMobileFlag: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$isMobileFlag ? 'center' : 'auto')};
  max-width: ${(props) => (props.$isMobileFlag ? '80%' : '100%')};
  row-gap: ${(props) => (props.$isMobileFlag ? '20px' : '0')};
`;

export const PageNumberContainer = styled.span`
  margin: 0 15px;
  margin-top: 5px;
`;

export const PDFToolbarButton = styled(Button)`
  margin-left: 15px;
  background-color: ${Color.AntdPrimary};
  color: ${Color.White};
`;

export const PDFResetButton = styled(Button)`
  margin-left: 10px;
`;

export const PDFPageContainer = styled(Row)<{ $isMobileFlag: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-left: ${(props) => (props.$isMobileFlag ? '-25px' : '0')};
`;

export const MoodboardButtonsContainer = styled.div`
  padding-bottom: 100px;
  margin-top: 20px;
`;

export const MoodboardPDFContainer = styled(Row)`
  margin-top: 20px;
`;

export const MoodboardUploadContainer = styled.div`
  margin-top: 0px;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoodboardUploadRow = styled(Row)`
  text-align: center;
  cursor: pointer;
`;

export const MoodboardNoUploadRow = styled(Row)`
  text-align: center;
`;

export const UploadTextContainer = styled.p`
  color: ${Color.UploadBlue};
  font-size: 16px;
  margin: 0;
`;
