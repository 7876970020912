import { createSlice } from '@reduxjs/toolkit';
import {
  getLanguageLocalStorage,
  setLanguageInStorage,
} from '../../utils/multiLanguage/antdLocale';

const initialState = {
  isSidebarCollapsed: false,
  apiCallCount: 0,
  isMobile: false,
  language: getLanguageLocalStorage(),
  libraryType: {},
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.isSidebarCollapsed = action.payload;
    },
    startApiCall: (state) => {
      state.apiCallCount += 1;
    },
    finishApiCall: (state) => {
      state.apiCallCount -= 1;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      setLanguageInStorage(action.payload);
    },
    setLibraryTypes: (state, action) => {
      state.libraryType = action.payload;
    },
  },
});

export const commonSliceReducer = commonSlice.reducer;
export const {
  toggleSidebar,
  startApiCall,
  finishApiCall,
  setIsMobile,
  setLanguage,
  setLibraryTypes,
} = commonSlice.actions;
