import { message } from 'antd';
import { useEffect, useState } from 'react';
import { ImageCropperModal } from 'react-image-editor-pro';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MoodboardSearchImage } from '../../../elements/images/StyledImages';
import i18n from '../../../plugins/i18n';
import { searchLaceImage } from '../../../utils/backendRequests/imageSearch/createLaceImageSearch';
import { parseScaledImageFile } from '../../../utils/parsers/parseScaledImageFile';
import { RootState } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { SearchMoodboardModalProps } from '../../moodboard/MoodboardTypes';

export function SearchMoodboardModal({
  isCropModal,
  setIsCropModal,
  imageUrl,
}: SearchMoodboardModalProps) {
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [originalImageFile, setOriginalImageFile] = useState<File | null>(null);
  const [scaledImageUrl, setScaledImageUrl] = useState<string | null>(null);
  const [imageFile, setImage] = useState<File | null>(null);
  const [, setNewImageUrl] = useState<string | null>(null);
  const [isSearchModal, setIsSearchModal] = useState(false);

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const navigate = useNavigate();

  /**
   * Convert a base64 image or Blob URL (from the backend) to a `File` object.
   */
  const base64ToFile = (base64: string, filename: string): File => {
    const [meta, content] = base64.split(',');
    const mime = meta.match(/:(.*?);/)?.[1];
    const binary = atob(content);
    const u8arr = Uint8Array.from(binary, (char) => char.charCodeAt(0));
    return new File([u8arr], filename, { type: mime });
  };

  // Convert imageUrl to File if it's a base64 string and set it for cropping
  useEffect(() => {
    if (!imageUrl) return;

    const _handleBase64 = () => {
      const file = base64ToFile(imageUrl, 'existing-image.png');
      setOriginalImageFile(file);
      setImage(file);
    };

    const _handleFileUrl = async () => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], 'existing-image.png', {
          type: blob.type,
        });
        setOriginalImageFile(file);
        setImage(file);
      } catch (error) {
        console.error('Error fetching file from URL:', error);
      }
    };

    const _setScaledImage = async () => {
      try {
        const file = await parseScaledImageFile(imageUrl);
        const newUrl = URL.createObjectURL(file);
        setScaledImageUrl(newUrl);
      } catch (error) {
        console.error('Error generating scaled image:', error);
      }
    };

    // Determine if `imageUrl` is base64 or a file URL
    if (imageUrl.startsWith('data:image')) {
      _handleBase64();
    } else {
      _handleFileUrl();
    }

    _setScaledImage();
  }, [imageUrl]);

  const imageToShow = scaledImageUrl || imageUrl;

  useEffect(() => {
    if (!isCropModal && croppedImageUrl) {
      setIsSearchModal(true);
    }
  }, [isCropModal, croppedImageUrl]);

  const submitSearch = () => {
    if (imageFile === null) {
      message.error(i18n.t('error_message:error_null_front_image'));
      return;
    }
    const data = new FormData();
    const frontImgFile = new File([imageFile!], 'search_image_front.jpeg', {
      type: imageFile.type,
    });
    data.append('search_image', frontImgFile);
    searchLaceImage(data, navigate, false);
  };

  return (
    <>
      <ImageCropperModal
        title={i18n.t('headers:edit_image')}
        reset={i18n.t('buttons:reset')}
        cancel={i18n.t('buttons:cancel')}
        upload={i18n.t('buttons:upload')}
        yPosition={i18n.t('headers:y_position')}
        xPosition={i18n.t('headers:x_position')}
        properties={i18n.t('headers:properties')}
        rotate={i18n.t('headers:rotate')}
        zooming={i18n.t('headers:zoom')}
        isMobile={isMobileFlag}
        setImage={setImage}
        imageUrl={imageToShow}
        setCroppedImageUrl={setCroppedImageUrl}
        setScaledImageUrl={setScaledImageUrl}
        originalImageFile={originalImageFile}
        scaledImageUrl={scaledImageUrl}
        setImageUrl={setNewImageUrl}
        setModalVisible={setIsCropModal}
        modalVisible={isCropModal}
      />
      <Modal
        style={{ marginTop: '5%' }}
        width={400}
        title={i18n.t('headers:lace_image_search')}
        open={isSearchModal}
        okText={i18n.t('buttons:search')}
        onCancel={() => setIsSearchModal(false)}
        cancelButtonProps={{ danger: true }}
        onOk={() => submitSearch()}
      >
        <MoodboardSearchImage
          src={croppedImageUrl as string}
          alt={i18n.t('headers:image_preview')}
        />
      </Modal>
    </>
  );
}
