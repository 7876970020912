import { TitleProp } from '../pages/PagesTypes';
import { SettingsPage } from '../pages/userSettings/SettingsPage';
import { RouteProps } from './RouteTypes';

export const routesUserSettings: RouteProps<TitleProp>[] = [
  {
    path: '/settings',
    title: 'Settings',
    page: SettingsPage,
  },
];
