import Material01 from './Material 01.png';
import Material01Dress from './Material 01_dress.png';
import Material02 from './Material 02.png';
import Material02Dress from './Material 02_dress.png';
import Material03 from './Material 03.png';
import Material03Dress from './Material 03_dress.png';
import Material04 from './Material 04.png';
import Material04Dress from './Material 04_dress.png';
import Material05 from './Material 05.png';
import Material05Dress from './Material 05_dress.png';
import Material06 from './Material 06.png';
import Material06Dress from './Material 06_dress.png';
import Material07 from './Material 07.png';
import Material07Dress from './Material 07_dress.png';
import Material08 from './Material 08.png';
import Material08Dress from './Material 08_dress.png';
import Material09 from './Material 09.png';
import Material10 from './Material 10.png';

const images = [
  Material01Dress,
  Material01,
  Material02Dress,
  Material02,
  Material03Dress,
  Material03,
  Material04Dress,
  Material04,
  Material05Dress,
  Material05,
  Material06Dress,
  Material06,
  Material07Dress,
  Material07,
  Material08Dress,
  Material08,
  Material09,
  Material10,
];

export default images; // Default export instead of named export
