import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { GenericTableReqProps, TableStateObj } from '../../types/Types';
import { api } from '../api/api';
import { Librarydata } from './LibraryTypes';

/**
 * Returns fabrics for a given Fabric Library
 */
export async function getFabrics(
  libraryId: string,
  tableState: TableStateObj,
  filterableColumns: TransformedFilterProps
): Promise<Librarydata> {
  const params: GenericTableReqProps = {
    page: tableState.current,
    page_size: tableState.pageSize,
  };
  // Add sort parameters only if both sortField and sortOrder have values
  if (tableState.sortField && tableState.sortOrder) {
    params.sort_by = tableState.sortField;
    params.sort_order = tableState.sortOrder;
  }
  if (filterableColumns) {
    params.filter = JSON.stringify(filterableColumns);
  }
  return await api({
    method: 'GET',
    url: `v1/library/${libraryId}`,
    showErrMsg: true,
    params: params,
  });
}
