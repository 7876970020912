import { message } from 'antd';
import { CreatePriceTagRequest } from '../../../components/prices/PricesTypes';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Creates a price tag for a fabric in a shared designer collection
 */
export async function createPriceTag(
  createPriceBody: CreatePriceTagRequest,
  collectionId: string,
  designerBrandId: string
): Promise<boolean> {
  try {
    await api({
      method: 'POST',
      url: `v1/collection/${collectionId}/designer_brand/${designerBrandId}/price_tag`,
      body: createPriceBody,
      showErrMsg: true,
    });
    message.success(i18n.t('long_messages:success_updated_price_tag'));
    return true;
  } catch (error) {
    return false;
  }
}
