import { CollectionPricesResp } from '../../../components/prices/PricesTypes';
import { TableStateObj } from '../../types/Types';
import { api } from '../api/api';

/**
 * Gets all collection price tag data for a given desginerId
 */
export async function getCollectionPrices(
  collectionId: string,
  designerBrandId: string,
  tableState: TableStateObj
): Promise<CollectionPricesResp> {
  const params = {
    fabrics_page: tableState.current,
    fabrics_page_size: tableState.pageSize,
  };
  return await api({
    method: 'GET',
    url: `v1/collection/${collectionId}/designer_brand/${designerBrandId}/price_tag`,
    params: params,
    showErrMsg: true,
  }).then((res: CollectionPricesResp) => {
    return res;
  });
}
