import { useParams } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import { TabTitle } from '../../components/TabTitle';
import { CreateOrEditFabricForm } from '../../components/form/CreateOrEditFabricForm';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';

export function CreateFabricPage() {
  const { libraryId } = useParams<{ libraryId?: string }>();
  // 1269px max width for form layout, ensures it stacks appropriately on screens up to this size.
  // We avoid using `isMobile` from Redux (768px) as it doesn’t cater to devices between mobile and small desktop
  // sizes, where we need more responsive adjustments.
  const isNarrowLayout = useMediaQuery({ query: '(max-width: 1269px)' });

  return (
    <MainLayout>
      <HeaderTitle title={i18n.t('page_titles:create_fabric_page')} />
      <TabTitle title={'Create Fabric'} />
      <CreateOrEditFabricForm
        libraryId={libraryId || ''}
        isNarrowLayout={isNarrowLayout}
        isCreate={true}
      />
    </MainLayout>
  );
}
