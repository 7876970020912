import { Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import i18n from 'i18next';
import { GroupingTitleContainer } from '../../elements/fabrics/fabricStyledElement';
import {
  GreenCheckedIcon,
  RedUnCheckedIcon,
} from '../../elements/icons/StyledIcons';
import { parseLocalDateTime } from '../../utils/parsers/parseLocalDateTime';
import { FabricTableGenericProps, columnFields } from './TableTypes';

// Ensure the array is sorted in ascending order
const pageOptionsArray = [20, 50, 100];
export const defaultPageSize = pageOptionsArray[1];

/**
 * Fabric table element with dynamic width.
 */
export const FabricTableGeneric = ({
  columns,
  data,
  rowSelection,
  rowKey = 'key',
  isBackendPagination = false,
  tableState,
  setTableState,
  sortableColumns,
}: FabricTableGenericProps) => {
  const total = tableState?.total || data.length;
  const paginationObj = {
    position: ['bottomCenter' as const],
    defaultPageSize: defaultPageSize,
    current: tableState?.current,
    pageSizeOptions: pageOptionsArray,
    showSizeChanger: true,
    total: total,
    hideOnSinglePage: total < pageOptionsArray[0],
  };

  const widthCalculation = (title: string | undefined) => {
    return (
      (title || '')
        .split(' ')
        .reduce((max, part) => Math.max(max, part.length), 0) * 15
    );
  };

  // Apply rendering logic for specific column types
  const applyColumnRenderLogic = (column: columnFields) => {
    if (!column.render && column.columnType === 'boolean') {
      column.render = (value: boolean) => {
        return value === true ? <GreenCheckedIcon /> : <RedUnCheckedIcon />;
      };
    } else if (!column.render && column.columnType === 'date') {
      column.render = (value: string) => {
        return value ? parseLocalDateTime(value) : null;
      };
    }
  };

  // Filter out specific columns and rearrange
  const filteredColumns = columns.filter(
    (col) => !['action'].includes(col.dataIndex as string)
  );

  const rearrangedColumns = [
    ...filteredColumns,
    ...columns.filter((col) => col.dataIndex === 'action'),
  ];

  // Apply the rendering logic before grouping
  rearrangedColumns.forEach(applyColumnRenderLogic);

  const groupedColumns = rearrangedColumns.reduce(
    (acc, column) => {
      const groupName = column.grouping as string;
      if (!acc[groupName]) {
        acc[groupName] = {
          title: i18n.t(`headers:${groupName}`),
          columns: [],
        };
      }

      const isSortable = sortableColumns?.includes(column.dataIndex as string);
      acc[groupName].columns.push({
        ...column,
        sort: isSortable ? true : false, // Add sorter here
      });

      // If there's only one column in the group, apply padding or alignment
      if (acc[groupName].columns.length === 1) {
        acc[groupName].title = (
          <GroupingTitleContainer>
            {acc[groupName].title}
          </GroupingTitleContainer>
        ) as unknown as string; // Type-cast to prevent type errors;
      }

      return acc;
    },
    {} as Record<string, { title: string; columns: columnFields[] }>
  );

  const finalColumns = Object.values(groupedColumns).flatMap((group) => [
    {
      title: group.title,
      children: group.columns.map((col: columnFields) => ({
        ...col,
        align: 'center',
        sorter: col.sort, // Ensure sorter is carried over
        width: widthCalculation(i18n.t(group.title)),
      })),
    },
  ]);

  return (
    <Table
      rowSelection={
        rowSelection as TableRowSelection<{ key: number }> // Explicitly set the type here
      }
      columns={finalColumns}
      dataSource={data.map((obj, index) => ({
        ...obj,
        key: index + 1,
      }))}
      rowKey={rowKey}
      pagination={paginationObj}
      sticky={{
        offsetHeader: 50,
      }}
      scroll={{ x: 'max-content' }}
      style={{ whiteSpace: 'pre-wrap', marginTop: '20px', width: '100%' }}
      title={() => i18n.t('headers:table_total_count', { count: total })}
      bordered
      onChange={(pagination, _, sorter) => {
        const sortOrder = Array.isArray(sorter)
          ? sorter[0]?.order
          : sorter?.order;
        const sortField = Array.isArray(sorter)
          ? sorter[0]?.field
          : sorter?.field;

        const fieldString = Array.isArray(sortField)
          ? sortField.join(', ')
          : sortField?.toString() || '';

        const mappedOrder =
          sortOrder === 'ascend'
            ? 'asc'
            : sortOrder === 'descend'
              ? 'desc'
              : '';

        const finalFieldString = mappedOrder === '' ? '' : fieldString;

        if (isBackendPagination && setTableState) {
          setTableState((prev) => ({
            ...prev,
            current: pagination.current as number,
            pageSize: pagination.pageSize as number,
            sortOrder: mappedOrder,
            sortField: finalFieldString,
          }));
        }
      }}
    />
  );
};
