import i18n from 'i18next';

import { BinTab } from './BinTab';

import { BulkUploadBinTabProps } from './BinTypes';

export function BulkUploadBinTab({
  bulkUploads,
  getBinContents,
}: BulkUploadBinTabProps) {
  const columns = [
    {
      dataIndex: 'library',
      title: i18n.t('headers:library'),
      columnType: 'string' as const,
    },
    {
      dataIndex: 'upload_type',
      title: i18n.t('column_titles:upload_type'),
      columnType: 'string' as const,
      // Consider updating the namespace if more upload_type enums are added
      render: (upload_type: string) => i18n.t(`buttons:${upload_type}`),
    },
    {
      dataIndex: 'successful_fabrics_count',
      title: i18n.t('column_titles:fabrics_added'),
      columnType: 'string' as const,
    },
    {
      dataIndex: 'created_at',
      title: i18n.t('column_titles:created_at'),
      columnType: 'date' as const,
    },
    {
      dataIndex: 'deleted_timestamp',
      title: i18n.t('column_titles:deleted_at'),
      columnType: 'date' as const,
    },
  ];

  return (
    <BinTab
      binData={bulkUploads}
      binColumns={columns}
      getBinContents={getBinContents}
      binType="bulkUploads"
    />
  );
}
