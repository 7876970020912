import { Button, Col, Row, Upload, message } from 'antd';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Modal } from '../../components/Modal';
import { Color } from '../../elements/base/Color';
import {
  FileActionCol,
  FileNameCol,
  FileText,
} from '../../elements/modals/StyledModal';
import { StyledAttachmentContainer } from '../../elements/StyledUI';
import { FileWrapper } from '../../elements/wrapper/FileWrapper';
import i18n from '../../plugins/i18n';
import { RootState, Setter } from '../../utils/types/Types';
import { AddFileTitle } from './AddFileTitle';
import { CustomUploadRequestOption } from './FileUpload';

interface FileEditUploadProps {
  attachments: File[] | null;
  setAttachments: Setter<File[] | null>;
  setDeleteAttachmentIDs: Setter<string[]>;
  setAttachmentsFiles: Setter<File[]>;
}

type Attachment = {
  name: string;
  id: string;
  url: string;
};

export function FileEditUpload({
  attachments,
  setAttachments,
  setDeleteAttachmentIDs,
  setAttachmentsFiles,
}: FileEditUploadProps) {
  const [fileList, setFileList] = useState<(File | Attachment)[]>(
    attachments || []
  );
  const [editModal, setEditModal] = useState<{
    visible: boolean;
    file: File | Attachment | null;
  }>({
    visible: false,
    file: null,
  });

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  const handleRemoveFile = () => {
    const fileProp = editModal.file; // Retrieve the file from state
    if (!fileProp) return;
    setFileList((prevFileList) => prevFileList.filter((f) => f !== fileProp));
    setAttachments((prevAttachments) => {
      if (prevAttachments) {
        return prevAttachments.filter((f) => f !== fileProp);
      }
      return prevAttachments;
    });
    if ('id' in fileProp) {
      setDeleteAttachmentIDs((prevIDs) => [...prevIDs, fileProp.id]);
    } else {
      setAttachmentsFiles((prevFiles) =>
        prevFiles.filter((f) => f !== fileProp)
      );
    }
    handleCancel();
  };

  const props = {
    multiple: true,
    showUploadList: false,
    beforeUpload: (_: File, bulkFileList: File[]) => {
      if (fileList.length + bulkFileList.length > 3) {
        message.error(i18n.t('error_messages:error_upload_too_many_files'));
        return false;
      }
      return true;
    },
    customRequest(options: unknown) {
      const { file } = options as CustomUploadRequestOption;
      setFileList((prevFileList) => [...prevFileList, file]);
      setAttachments((prevAttachments) => {
        if (!prevAttachments) {
          return [file];
        } else if (prevAttachments.length < 3) {
          return [...prevAttachments, file];
        } else {
          return prevAttachments;
        }
      });
      setAttachmentsFiles((prevFiles) => [...prevFiles, file]); // Add new file to state
    },
  };

  // Close modal
  const handleCancel = () => {
    setEditModal({
      visible: false,
      file: null,
    });
  };

  const openModal = (file: File | Attachment) => {
    setEditModal({
      visible: true,
      file: file, // Store the file to be potentially deleted
    });
  };

  useEffect(() => {
    if (attachments) {
      setFileList(attachments);
    }
  }, [attachments]);

  return (
    <>
      <Modal
        title={i18n.t('headers:delete_attachment')}
        open={editModal.visible}
        onOk={handleRemoveFile}
        onCancel={handleCancel}
        okText={i18n.t('buttons:remove')}
        cancelText={i18n.t('buttons:cancel')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        {i18n.t('long_messages:confirm_attachment_delete')}
      </Modal>
      <StyledAttachmentContainer
        direction="vertical"
        $isMobileFlag={isMobileFlag}
      >
        <Upload {...props}>
          <FileWrapper>
            <AddFileTitle />
          </FileWrapper>
        </Upload>
        <Row gutter={[16, 16]}>
          {fileList.map((file, index) => (
            <Col key={index} span={24}>
              <Row justify="space-between">
                <FileNameCol span={16} $isMobileFlag={isMobileFlag}>
                  <FileText>{file.name}</FileText>
                </FileNameCol>
                <FileActionCol span={8} $isMobileFlag={isMobileFlag}>
                  <Button type="link" onClick={() => openModal(file)}>
                    {i18n.t('buttons:remove')}
                  </Button>
                </FileActionCol>
              </Row>
            </Col>
          ))}
        </Row>
      </StyledAttachmentContainer>
    </>
  );
}
