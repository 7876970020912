import { Table } from 'antd';
import i18n from 'i18next';
import {
  GreenCheckedIcon,
  RedUnCheckedIcon,
} from '../../elements/icons/StyledIcons';
import { parseLocalDateTime } from '../../utils/parsers/parseLocalDateTime';
import { TableGenericProps, columnFields } from './TableTypes';

// Ensure the array is sorted in ascending order
const pageOptionsArray = [20, 50, 100];
export const defaultPageSize = pageOptionsArray[1];

/**
 * Generic table element with dynamic width.
 *
 * Note: This logic cannot handle backend pagination.
 *
 * Note: Antd Table currently sets the width of the column as the length of the\
 * data itself. However, if your data is shorter than the column title, the title
 * is cut off.
 *
 * Looping over column names:
 *   - If title exists: width is longest word multiplied by 15px per character
 *     - 14 to account for the font size, 1 to account for letter spacing
 *   - Else is 0
 * Width of Table is then set to max width value.
 */
export const TableGeneric = ({
  columns,
  data,
  rowSelection,
  rowKey = 'key',
  isBackendPagination = false,
  tableState,
  setTableState,
  isBackendPaginated = true,
}: TableGenericProps) => {
  const total = tableState?.total || data.length;
  const paginationObj = {
    position: ['bottomCenter' as const],
    defaultPageSize,
    current: tableState?.current,
    pageSizeOptions: pageOptionsArray,
    showSizeChanger: true,
    total,
    hideOnSinglePage: total < pageOptionsArray[0],
  };

  /** Calculate width for given column title:
   * - Check if a title exists
   * - Split the title into an array of words
   * - Get the longest word and multiply it by 15px per character
   *
   * - Set to zero width if there is no title
   */
  const widthCalculation = (title: string | undefined) =>
    (title || '')
      .split(' ')
      .reduce((max, part) => Math.max(max, part.length), 0) * 15;

  // Helper function to safely get the value by dataIndex
  const getValueByDataIndex = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    record: Record<string, any>,
    dataIndex?: string | string[]
  ) => {
    if (!dataIndex) return undefined;
    if (Array.isArray(dataIndex)) {
      return dataIndex.reduce(
        (value, key) => (value ? value[key] : undefined),
        record
      );
    }
    return record[dataIndex];
  };

  // Sorting logic for various column types
  const getSorter = (
    column: columnFields,
    isBackendPaginated: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): ((a: any, b: any) => number) | boolean | undefined => {
    const { columnType, dataIndex } = column;
    if (isBackendPaginated || !columnType || !dataIndex) return undefined;

    switch (columnType) {
      case 'string':
        return (a, b) =>
          (getValueByDataIndex(a, dataIndex) || '').localeCompare(
            getValueByDataIndex(b, dataIndex) || ''
          );
      case 'number':
        return (a, b) =>
          (getValueByDataIndex(a, dataIndex) || 0) -
          (getValueByDataIndex(b, dataIndex) || 0);
      case 'date':
        return (a, b) =>
          new Date(getValueByDataIndex(a, dataIndex) || '').getTime() -
          new Date(getValueByDataIndex(b, dataIndex) || '').getTime();
      case 'boolean':
        return (a, b) => {
          const aValue = getValueByDataIndex(a, dataIndex);
          const bValue = getValueByDataIndex(b, dataIndex);
          return aValue === bValue ? 0 : aValue ? 1 : -1;
        };
      default:
        return undefined;
    }
  };

  const transformedColumns = columns.map((column) => {
    const isSortable = column.columnType ? true : false;
    const sorter = getSorter(column, isBackendPaginated);

    if (!column.render) {
      /**
       * If columnType is boolean, render the value as a check, a cross, or null
       * for true, false, and others respectively.
       * but only if no render function is provided
       */
      if (column.columnType === 'boolean') {
        column.render = (value: boolean) => {
          if (value === true) return <GreenCheckedIcon />;
          if (value === false) return <RedUnCheckedIcon />;
          return null;
        };
      } else if (column.columnType === 'date') {
        // Format date and time
        column.render = (value: string) =>
          value ? parseLocalDateTime(value) : null;
      }
    }

    const width = column.title
      ? widthCalculation(i18n.t(column.title))
      : '75px';

    return {
      ...column,
      align: 'center' as const, // Use 'as const' instead of explicit type assertion
      width,
      sorter: isSortable ? sorter : undefined,
    };
  });

  return (
    <Table
      rowSelection={rowSelection}
      columns={transformedColumns}
      dataSource={data.map((obj, index) => ({
        ...obj,
        key: index + 1,
      }))}
      rowKey={rowKey}
      pagination={paginationObj}
      sticky={{
        offsetHeader: 50,
      }}
      scroll={{ x: 'max-content' }}
      style={{ whiteSpace: 'pre-wrap', marginTop: '20px', width: '100%' }}
      title={() => i18n.t('headers:table_total_count', { count: total })}
      bordered
      // Update pagination state if backend pagination is enabled
      onChange={(pagination, _, sorter) => {
        // Handle sorter being an array or undefined
        const sortOrder = Array.isArray(sorter)
          ? sorter[0]?.order
          : sorter?.order;
        const sortField = Array.isArray(sorter)
          ? sorter[0]?.field
          : sorter?.field;
        // Map 'ascend' and 'descend' to 'asc' and 'desc'
        const mappedOrder =
          sortOrder === 'ascend'
            ? 'asc'
            : sortOrder === 'descend'
              ? 'desc'
              : '';
        // If sortOrder is empty, set fieldString to an empty string
        const finalFieldString =
          mappedOrder === '' ? '' : sortField?.toString() || '';

        // Check if the page or sorter has changed
        if (isBackendPagination && setTableState) {
          setTableState((prev) => ({
            ...prev,
            current: pagination.current as number,
            pageSize: pagination.pageSize as number,
            sortOrder: mappedOrder,
            sortField: finalFieldString,
          }));
        }
      }}
    />
  );
};
