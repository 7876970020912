import { TitleProp } from '../pages/PagesTypes';
import { SuppliersPage } from '../pages/suppliers/SuppliersPage';
import { RouteProps } from './RouteTypes';

export const routesSuppliers: RouteProps<TitleProp>[] = [
  {
    path: '/suppliers',
    title: 'Suppliers',
    key: 'suppliers_page',
    page: SuppliersPage,
  },
];
