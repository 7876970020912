import { Space } from 'antd';

import i18n from '../../../plugins/i18n';
import { AdminLabel } from '../../../utils/backendRequests/admin/AdminTypes';
import { AdminUpdateLabelNameModal } from '../../modal/admin/AdminUpdateLabelNameModal';
import { LabelColumn } from '../TableTypes';

export const labelColumns = (
  handleViewLabel: (label: AdminLabel) => void,
  getLabels: () => Promise<void>
): LabelColumn[] => [
  {
    dataIndex: 'name',
    columnType: 'string',
    title: i18n.t('column_titles:name'),
    render: (_, label: AdminLabel) => {
      return <Space size="middle">{label.name}</Space>;
    },
  },
  {
    dataIndex: 'id',
    title: i18n.t('column_titles:action'),
    render: (_, label: AdminLabel) => {
      return (
        <Space size="middle">
          {!label.isNull && (
            <AdminUpdateLabelNameModal
              labelData={label}
              getLabels={getLabels}
            />
          )}
          <a onClick={() => handleViewLabel(label)} role="button">
            {i18n.t('column_titles:view')}
          </a>
        </Space>
      );
    },
  },
];
