import { ColorPicker, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import {
  ColourImageContainer,
  ColoursDesktopContainer,
  ColoursMobileContainer,
  ColoursTitleText,
  DesktopColourSubContainer,
  DesktopImageContainerRow,
  DesktopSelectSearch,
  DesktopToolboxContainer,
  FabricColourContainer,
  ImageContainerCol,
  ImageOverlayContainer,
  MobileColourSubContainer,
  MobileSelectSearch,
  MobileToolboxContainer,
  ResetColourButton,
  StyledColourImage,
} from '../../../elements/colours/StyledColour';
import i18n from '../../../plugins/i18n';
import { Colour, DisplayColour } from '../../DisplayColour';
import { ColorFactoryInstance } from '../../modal/colour/CreateColourModal';
import { FabricColoursProps } from '../FabricTypes';

export type colourData = {
  id: string;
  name: string;
  hex_value: string | null;
};

/**
 * A tab component to display downloadable attachments of one fabric.
 */
export function FabricColoursTab({
  fabric,
  images,
  isMobile,
}: FabricColoursProps) {
  const [selectedHex, setSelectedHex] = useState<string | null>(null);
  const [imageSizes, setImageSizes] = useState<
    { width: number; height: number }[]
  >([]);

  // On mount, calculate image sizes
  useEffect(() => {
    const containerWidth = 200; // Container size (fixed or dynamic as needed)
    const containerHeight = 200;

    images.forEach((img) => {
      const imgElement = new Image();
      imgElement.src = img;

      imgElement.onload = () => {
        const { width, height } = calculateImageSize(
          containerWidth,
          containerHeight,
          imgElement.width,
          imgElement.height
        );
        setImageSizes((prev) => [...prev, { width, height }]);
      };
    });
  }, [images]);

  const handleColorClick = (hexValue: string | null) => {
    setSelectedHex(hexValue);
  };

  const handleReset = () => {
    setSelectedHex(null);
  };

  const handleColorChange = (color: ColorFactoryInstance) => {
    // Set selected color's hex value from the ColorPicker
    setSelectedHex('#' + color.toHex());
  };

  const colourPropsArray = fabric.colours as unknown as colourData[];
  const colours = colourPropsArray.map((colour) => ({
    title: colour.name,
    hexValue: colour.hex_value,
    tooltipShow: true,
  }));

  // New function to calculate image size based on container size
  const calculateImageSize = (
    containerWidth: number,
    containerHeight: number,
    imgWidth: number,
    imgHeight: number
  ) => {
    const containerAspectRatio = containerWidth / containerHeight;
    const imageAspectRatio = imgWidth / imgHeight;

    let width, height;

    if (imageAspectRatio > containerAspectRatio) {
      // Image is wider than the container
      width = containerWidth;
      height = width / imageAspectRatio;
    } else {
      // Image is taller than the container or fits exactly
      height = containerHeight;
      width = height * imageAspectRatio;
    }

    return { width, height };
  };

  // Determine the image display logic based on the number of images
  const renderImages = () => {
    const imageCount = images.length;
    const rows = [];

    for (let i = 0; i < imageCount; i++) {
      const imageSize = imageSizes[i] || { width: 0, height: 0 };

      let spanStyle;
      if (isMobile) {
        spanStyle = 24;
      } else if (images.length === 1) {
        spanStyle = 8;
      } else if (images.length === 2) {
        spanStyle = 12;
      } else {
        spanStyle = 6;
      }

      rows.push(
        <ImageContainerCol span={spanStyle} key={`image-${i}`}>
          <ColourImageContainer $isMobile={isMobile}>
            <StyledColourImage
              src={images[i]}
              alt="Fabric"
              $width={imageSize.width}
              $height={imageSize.height}
            />
            {selectedHex && (
              <ImageOverlayContainer
                $width={imageSize.width}
                $height={imageSize.height}
                $selectedHex={selectedHex}
              />
            )}
          </ColourImageContainer>
        </ImageContainerCol>
      );
    }

    return rows;
  };

  return (
    <>
      {isMobile ? (
        <ColoursMobileContainer>
          <MobileColourSubContainer>
            {i18n.t('page_subtitles:colours_tab_sub')}
          </MobileColourSubContainer>
          {colours.length > 0 && (
            <>
              <ColoursTitleText>
                {i18n.t('headers:fabric_colours')}
              </ColoursTitleText>
              <DesktopColourSubContainer>
                {i18n.t('page_subtitles:colours_fabric_sub')}
              </DesktopColourSubContainer>
              <FabricColourContainer>
                <DisplayColour
                  colours={colours as Colour[]}
                  isTechnical={true}
                />
              </FabricColourContainer>
            </>
          )}
          <MobileToolboxContainer>
            <div>
              <Space>
                <strong>{i18n.t('headers:current_colour')}</strong>
                {selectedHex
                  ? selectedHex.toUpperCase()
                  : i18n.t('headers:none')}
                <ColorPicker
                  value={selectedHex ? selectedHex : undefined}
                  onChange={handleColorChange}
                />
              </Space>
            </div>
            {colours.length > 0 && (
              <Row>
                <Space>
                  {i18n.t('headers:fabric_colours')}
                  <MobileSelectSearch
                    placeholder={i18n.t('buttons:colour')}
                    onChange={handleColorClick}
                    value={selectedHex || undefined}
                    options={colours.map((colour) => ({
                      label: colour.title,
                      value: colour.hexValue as string,
                    }))}
                  />
                </Space>
              </Row>
            )}
            <ResetColourButton onClick={handleReset}>
              {i18n.t('buttons:Reset')}
            </ResetColourButton>
          </MobileToolboxContainer>
          <Row gutter={[16, 16]}>{renderImages()}</Row>
        </ColoursMobileContainer>
      ) : (
        <ColoursDesktopContainer>
          <DesktopColourSubContainer>
            {i18n.t('page_subtitles:colours_tab_sub')}
          </DesktopColourSubContainer>
          {colours.length > 0 && (
            <>
              <ColoursTitleText>
                {i18n.t('headers:fabric_colours')}
              </ColoursTitleText>
              <DesktopColourSubContainer>
                {i18n.t('page_subtitles:colours_fabric_sub')}
              </DesktopColourSubContainer>
              <FabricColourContainer>
                <DisplayColour
                  colours={colours as Colour[]}
                  isTechnical={true}
                />
              </FabricColourContainer>
            </>
          )}
          <DesktopToolboxContainer>
            <div>
              <Space>
                <strong>{i18n.t('headers:current_colour')}</strong>
                {selectedHex
                  ? selectedHex.toUpperCase()
                  : i18n.t('headers:none')}
                <ColorPicker
                  value={selectedHex ? selectedHex : undefined}
                  onChange={handleColorChange}
                />
              </Space>
            </div>
            {colours.length > 0 && (
              <Row>
                <Space>
                  {i18n.t('headers:fabric_colours')}
                  <DesktopSelectSearch
                    placeholder={i18n.t('buttons:colour')}
                    onChange={handleColorClick}
                    value={selectedHex || undefined}
                    options={colours.map((colour) => ({
                      label: colour.title,
                      value: colour.hexValue as string,
                    }))}
                  />
                </Space>
              </Row>
            )}
            <ResetColourButton onClick={handleReset}>
              {i18n.t('buttons:Reset')}
            </ResetColourButton>
          </DesktopToolboxContainer>
          <DesktopImageContainerRow gutter={[16, 16]}>
            {renderImages()}
          </DesktopImageContainerRow>
        </ColoursDesktopContainer>
      )}
    </>
  );
}
