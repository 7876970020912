import { api } from '../api/api';

/**
 * Makes an API call for available libraries the current
 * user can view.
 */
export type libraryProp = {
  owner: string;
  name: string;
  is_owner: boolean;
  id: string;
  description: string | null;
  created_at: string;
};

export type LibraryListType = libraryProp[];

export async function getLibraries(
  libraryTypes: Record<string, string>,
  libraryType: string
): Promise<LibraryListType> {
  const libraryTypekey = Object.entries(libraryTypes).find(
    ([, value]) => value === libraryType
  ) || [''];

  const data = { library_type: Number(libraryTypekey[0]) };
  const response = await api({
    method: 'GET',
    url: 'v1/library',
    params: data,
    showErrMsg: true,
  });
  return response;
}
