import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Upload, message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import i18n from 'i18next';
import { useState } from 'react';
import { TextB3 } from '../../../elements/typography/TextB3';
import { createMoodboard } from '../../../utils/backendRequests/moodboard/createMoodboard';
import { UploadMoodboardProps } from '../../moodboard/MoodboardTypes';

export function UploadMoodboardModal({
  id,
  pageType,
  uploadModalVisible,
  handleCancelUpload,
  getMoodboardData,
}: UploadMoodboardProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  const handleFileRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const handleUpload = async () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      const file = fileList[0] as UploadFile;
      formData.append('attachment', file.originFileObj as Blob);

      const response = await createMoodboard(id, pageType, formData);
      if (response) {
        message.success(i18n.t('long_message:moodboard_upload_success'));
        getMoodboardData();
        handleCancelModal();
      }
    } else {
      message.error(i18n.t('error_messages:file_not_selected'));
    }
  };

  const handleCancelModal = () => {
    setFileList([]);
    handleCancelUpload();
  };

  const props = {
    maxCount: 1,
    accept: 'application/pdf',
    beforeUpload: () => false, // Prevent automatic upload
    onChange: handleFileChange,
    onRemove: handleFileRemove,
    fileList,
  };

  return (
    <Modal
      title={i18n.t('headers:upload_file_for_moodboard')}
      open={uploadModalVisible}
      onCancel={handleCancelModal}
      cancelButtonProps={{ danger: true }}
      onOk={handleUpload}
      okText={i18n.t('buttons:upload_file')}
    >
      <Row style={{ marginTop: '20px' }}>
        {i18n.t('long_message:message_create_moodboard')}
      </Row>
      <Row style={{ marginTop: '10px' }}>{i18n.t('headers:input_file')}:</Row>
      <Row wrap={false}>
        <Upload {...props}>
          <Button disabled={fileList.length !== 0} icon={<UploadOutlined />}>
            {i18n.t('buttons:select_pdf')}
          </Button>
        </Upload>
      </Row>
      <Row style={{ marginTop: '5px' }}>
        <TextB3>{i18n.t('long_messages:moodboard_upload_file_rule')}</TextB3>
      </Row>
    </Modal>
  );
}
