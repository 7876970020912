import { Space } from 'antd';

import { SupplierUserColumnValues } from '../../../utils/backendRequests/admin/AdminTypes';
import { EditSupplierUser } from './EditSupplierUser';

export const supplierUserColumns = (getUsers: () => void) => [
  {
    dataIndex: 'key',
    key: 'key',
  },
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Name',
    columnType: 'string',
  },
  {
    dataIndex: 'email',
    key: 'email',
    title: 'Email',
    columnType: 'string',
  },
  {
    dataIndex: 'supplier_brand',
    key: 'supplier_brand',
    title: 'Supplier Brand',
    columnType: 'string',
  },
  {
    dataIndex: 'country',
    key: 'country',
    title: 'Country',
    columnType: 'string',
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    columnType: 'string',
  },
  {
    dataIndex: 'account_type',
    title: 'Account Type',
    columnType: 'string',
  },
  {
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    title: 'Measurement Unit',
    columnType: 'string',
  },
  {
    dataIndex: 'is_active',
    key: 'is_active',
    title: 'Active',
    columnType: 'boolean',
  },
  {
    dataIndex: 'is_email_notified',
    key: 'is_email_notified',
    title: 'Email Notifications',
    columnType: 'boolean',
  },
  {
    dataIndex: 'is_2fa_enabled',
    title: '2FA Enabled',
    columnType: 'boolean',
  },
  {
    key: 'action',
    title: 'Action',
    render: (record: SupplierUserColumnValues) => {
      return (
        <Space size="middle">
          <EditSupplierUser
            getUsers={getUsers}
            user={record}
            supplierUserId={record.supplier_id}
          />
        </Space>
      );
    },
  },
];
