import { message } from 'antd';
import i18n from 'i18next';
import { NavigateProp, Paths } from '../../types/Types';
import { api } from '../api/api';

/**
 * Edit Library name & description.
 * After a library is successfully edited, the library
 * list stored in redux is refreshed.
 */
export async function editLibrary(
  id: string,
  description: string | null,
  name: string,
  navigate: NavigateProp,
  paths: Paths
): Promise<void> {
  const data = { description, name };
  await api({
    method: 'PUT',
    url: `v1/library/${id}`,
    body: data,
    showErrMsg: true,
    headers: {
      'content-type': 'application/json',
    },
  });
  navigate(paths.FABRICS, { state: { data } });
  message.success(i18n.t('long_messages:message_library_updated_success'));
}
