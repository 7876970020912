import { Button, Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import i18n from 'i18next';

import {
  BackendFilterProps,
  CheckboxFilterProps,
  FilterValues,
} from '../FilterTypes';

export function CertFilter({
  choices,
  field,
  currentFilterValues,
  setCurrentFilterValues,
}: CheckboxFilterProps) {
  /**
   * Any time a checkbox value is changed, this function is called.
   * The new filter values are set to the currentFilterValues state
   */
  const _onApplyFilter = (checkedValues: string[]) => {
    const newFilterValues: FilterValues | BackendFilterProps = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: checkedValues.map((value) => String(value)),
      },
    };

    setCurrentFilterValues(newFilterValues);
  };

  const checkboxOptions = choices.map((element) => (
    <Row key={element} style={{ paddingTop: '5px' }}>
      <Checkbox value={element}>
        <div style={{ wordBreak: 'break-word' }}>{element}</div>
      </Checkbox>
    </Row>
  ));

  const onIncludeNullUpdate = (event: CheckboxChangeEvent) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        has_null: event.target.checked,
      },
    };
    setCurrentFilterValues(newFilterValues);
  };

  if (choices.length === 0) {
    return <>{i18n.t('long_messages:null_data_filter')}</>;
  } else if (choices.length > 50) {
    return <>{i18n.t('long_messages:message_too_many_filter_options')}</>;
  } else {
    return (
      <>
        <Checkbox.Group
          onChange={_onApplyFilter}
          value={currentFilterValues[field].values}
        >
          <Col>{checkboxOptions}</Col>
        </Checkbox.Group>
        <Row style={{ padding: '10px 0px 10px 0px' }}>
          <Button.Group>
            <Button
              disabled={
                currentFilterValues[field].values.length === choices.length
              }
              onClick={() => _onApplyFilter(choices)}
            >
              {i18n.t('buttons:select_all')}
            </Button>
            <Button
              disabled={currentFilterValues[field].values.length === 0}
              onClick={() => _onApplyFilter([])}
            >
              {i18n.t('buttons:deselect_all')}
            </Button>
          </Button.Group>
        </Row>
        {!currentFilterValues[field].required && (
          <Row>
            <Checkbox
              checked={currentFilterValues[field].has_null}
              onChange={onIncludeNullUpdate}
            >
              {i18n.t('long_messages:show_null_rows')}
            </Checkbox>
          </Row>
        )}
      </>
    );
  }
}
