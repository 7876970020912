import { Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../components/TabTitle';
import { CreateBulkUploadModal } from '../components/modal/bulkUpload/CreateBulkUploadModal';
import { EditBulkUploadModal } from '../components/modal/bulkUpload/EditBulkUploadModal';
import { BulkUploadList } from '../components/table/bulkUploadList/BulkUploadList';
import { HeaderTitle } from '../components/title/HeaderTitle';
import { ButtonBasic } from '../elements/buttons/StyledButtons';
import { MainLayout } from '../layouts/Main';
import { routesNamedDict } from '../routes';
import { BulkUpload } from '../utils/backendRequests/bulkUpload/BulkUploadTypes';
import { getBulkUploads } from '../utils/backendRequests/bulkUpload/getBulkUploads';
import { RootAuth, RootState } from '../utils/types/Types';
import { TitleProp } from './PagesTypes';

export const BulkUploadPage = ({ title }: TitleProp) => {
  const [data, setData] = useState<BulkUpload[]>([]);
  const [createModal, setCreateModal] = useState({ visible: false });
  const [editModal, setEditModal] = useState({ visible: false });
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const { role } = useSelector((state: RootAuth) => state.auth);

  const fetchData = async () => {
    setData(await getBulkUploads());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    // Redirect if role is not 'supplier'
    if (role !== 'supplier') {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:import_page')}
        subtitle={i18n.t('page_subtitles:import_sub')}
      />
      <TabTitle title={title} />
      <CreateBulkUploadModal
        modal={createModal}
        setModal={setCreateModal}
        fetchData={fetchData}
      />
      <EditBulkUploadModal
        modal={editModal}
        setModal={setEditModal}
        fetchData={fetchData}
      />
      <Row justify="start">
        <Space direction={isMobileFlag ? 'vertical' : 'horizontal'}>
          {account !== 'viewer' && (
            <Space>
              <ButtonBasic onClick={() => setCreateModal({ visible: true })}>
                {i18n.t('buttons:create_fabrics')}
              </ButtonBasic>
              <ButtonBasic onClick={() => setEditModal({ visible: true })}>
                {i18n.t('buttons:edit_fabrics')}
              </ButtonBasic>
            </Space>
          )}
        </Space>
      </Row>
      <Row>
        <BulkUploadList title={title} data={data} getBulkUploads={fetchData} />
      </Row>
    </MainLayout>
  );
};
