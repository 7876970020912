import { store } from '..';
import { getLibraryTypes } from '../../../utils/backendRequests/list/getLibraryTypes';
import { setLibraryTypes } from '../common';

type AppDispatch = typeof store.dispatch;

/*
 * Fetches all library types from the backend and updates the store as a record of key-ID pairs.
 */
export const fetchAndSetLibraryTypes = async (dispatch: AppDispatch) => {
  try {
    const data = await getLibraryTypes();
    dispatch(setLibraryTypes(data));
  } catch (error) {
    console.error(error);
  }
};
