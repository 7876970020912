import { InspirationsListPage } from '../pages/inspirations/InspirationsListPage';
import { TitleProp } from '../pages/PagesTypes';
import { RouteProps } from './RouteTypes';

export const routesInspirations: RouteProps<TitleProp>[] = [
  {
    path: '/inspirations',
    title: 'Inspirations',
    key: 'inspirations_page',
    page: InspirationsListPage,
  },
];
