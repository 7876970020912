import { Image, Space } from 'antd';

import placeholderImage from '../../../assets/images/placeholder-image.png';
import { CertificationDownloadIcon } from '../../../elements/certifications/StyledCertifications';
import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { ColourRecord } from '../../../pages/colours/ColoursPage';
import i18n from '../../../plugins/i18n';
import { formFieldsProp } from '../../../utils/fabrics/FabricsTypes';
import {
  priceParser,
  similarityScoreParser,
} from '../../../utils/fabrics/parseFabric';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { getSelectedLabelsFromLocalStorage } from '../../../utils/fabrics/selectedFabricTableColumnsLabels';
import { parseTranslatedCompositions } from '../../../utils/parsers/parseTranslatedCompositions';
import { record } from '../../../utils/types/Types';
import { DisplayColour } from '../../DisplayColour';
import { DisplayCertifications } from '../../certification/DisplayCertifications';
import { BasicFields } from '../../fabric/FabricTypes';
import { FabricDetailsLink } from '../../fabric/detail/FabricDetailsLink';
import { EditFabricLink } from '../../fabric/edit/EditFabricLink';
import { DesktopObj } from '../TableTypes';
import { attachmentProp, selectableColumns } from './ColumnsDesktop';

export const selectableDefaultColumnsLabels = [];

/**
 * Constructs columns for a fabric table based on specified Mobile fabric fields.
 */
export const getSelectedMobileColumns = (
  pageType: string,
  measurementUnit: string | null,
  selectableColumnNames: string[],
  role: string | null,
  account: string | null,
  includeSimilarity?: boolean
) => {
  const storedLabels = getSelectedLabelsFromLocalStorage(true);

  const selectedColumns = selectableColumns.filter((column) =>
    selectableColumnNames.includes(column.name)
  );
  const columns: DesktopObj[] = [
    {
      dataIndex: 'name',
      title: parseFabricTitles('name', measurementUnit),
      columnType: 'string',
      grouping: 'basic',
    },
    {
      dataIndex: 'image_url_front',
      title: parseFabricTitles('image_url_front', measurementUnit),
      grouping: 'basic',
      render: (_: unknown, record: record) => {
        const text = record.image_url_front;
        const imageKey = record.id + '_image_url_front';
        return (
          <FrontImageStyled
            key={imageKey}
            width={'24vw'}
            height={'24vw'}
            src={text ? text : placeholderImage}
            alt={parseFabricTitles('image_url_front', measurementUnit)}
          />
        );
      },
    },
  ];

  const selectedFabricProperties = selectedColumns.filter(
    (fabricProperty: BasicFields) => storedLabels.includes(fabricProperty.name)
  );

  //Push similarity column for image search which contains the score field
  if (includeSimilarity) {
    const similarityColumn = returnOrderedFields(['similarity_score']);
    selectedFabricProperties.push(...similarityColumn);
  }

  selectedFabricProperties.forEach((element: formFieldsProp) => {
    const obj: DesktopObj = {
      dataIndex: element.name,
      title: parseFabricTitles(element.name, measurementUnit),
      columnType: element.columnType,
      grouping:
        element.name === 'similarity_score' ? 'basic' : element.grouping,
    };

    if (element.name === 'similarity_score') {
      obj.render = (_: unknown, record: record) => {
        return similarityScoreParser(record['similarity_score'] as number);
      };
    } else if (element.currency === true) {
      obj.render = (_: unknown, record: record) => {
        return priceParser(
          record.currency as string,
          record[element.name as keyof record] as number | null
        );
      };
    } else if (element.name === 'compositions') {
      obj.render = (_, record: record) => {
        const translatedComposition = parseTranslatedCompositions(
          record.compositions,
          false
        );
        return <span>{translatedComposition}</span>;
      };
    } else if (element.name === 'colours') {
      obj.render = (_, record: record) => {
        const colours =
          (record[element.name as keyof record] as ColourRecord[]) || [];

        // Check if colours is an array and format it to match the structure expected by DisplayColour
        const formattedColours = Array.isArray(colours)
          ? colours.map((colour: ColourRecord) => ({
              title: colour.name,
              hexValue: colour.hex_value,
              tooltipShow: true, // Assuming tooltip should be shown for all colours
            }))
          : [];

        return formattedColours.length > 0 ? (
          <DisplayColour colours={formattedColours} />
        ) : null;
      };
    }
    if (element.name === 'attachments') {
      obj.render = (_, record: record) => {
        const attachments = record[element.name as keyof record];

        // Check if attachments is an array and is of the correct type
        if (Array.isArray(attachments)) {
          return (
            <div>
              {attachments.map((attachment, index) => {
                // Type assertion here
                const att = attachment as attachmentProp;
                return (
                  <div key={index}>
                    <Space>
                      {att.name}
                      <a
                        href={att.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CertificationDownloadIcon />
                      </a>
                    </Space>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return null; // Return null if attachments is not valid
        }
      };
    } else if (element.fieldType === 'image') {
      obj.render = (_: unknown, record: record) => {
        const text = record[element.name as keyof record] as string; // Accessing using bracket notation
        const imageKey = record.id + '_' + element.name;
        return (
          <Image
            key={imageKey}
            width={'8vw'}
            height={'8vw'}
            src={text ? text : placeholderImage}
            alt={element.name}
            style={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        );
      };
    } else if (element.name === 'certifications') {
      obj.render = (_: unknown, record: record) => {
        return (
          <DisplayCertifications
            certArray={record.certifications}
            key={record.id}
          />
        );
      };
    }
    columns.push(obj);
  });

  const isViewButtonVisibile = pageType !== 'collection' || role === 'designer';
  const isEditButtonVisibile = role === 'supplier' && account !== 'viewer';

  if (pageType !== 'noAuth' && (isViewButtonVisibile || isEditButtonVisibile)) {
    columns.push({
      title: i18n.t('column_titles:action'),
      dataIndex: 'action',
      fixed: 'right',
      grouping: 'manage',
      render: (_: unknown, record: record) => (
        <Space size="middle">
          {isViewButtonVisibile && (
            <FabricDetailsLink pageType={pageType} record={record} />
          )}
          {isEditButtonVisibile && (
            <EditFabricLink pageType={pageType} record={record} />
          )}
        </Space>
      ),
    });
  }

  return columns;
};
