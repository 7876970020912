import { Space } from 'antd';
import i18n from 'i18next';

import { AdminCertReviewResponse } from '../../../utils/backendRequests/admin/AdminTypes';
import { CertStatusRender } from '../../certification/CertStatusRender';
import { AdminVerifyCertDetails } from './AdminVerifyCertDetails';

export type CertReviewColumn = {
  dataIndex: string;
  title: string;
  columnType?: string;
  render?: (
    text: string,
    record: AdminCertReviewResponse
  ) => JSX.Element | undefined;
};

export const CertColumns = (onSuccess: () => void): CertReviewColumn[] => {
  return [
    {
      dataIndex: 'certification_name',
      title: 'Certification Name',
      columnType: 'string',
    },
    {
      dataIndex: 'certification_type',
      title: 'Certification Type',
      columnType: 'string',
    },
    {
      dataIndex: 'supplier_brand',
      title: 'Supplier Brand',
      columnType: 'string',
    },
    {
      dataIndex: 'status',
      title: 'Status',
      columnType: 'string',
      render: (status: string) => <CertStatusRender status={status} />,
    },
    {
      dataIndex: 'expiry_date',
      title: 'Expiry Date',
      columnType: 'date',
    },
    {
      dataIndex: 'url',
      title: 'Download File',
      render: (url: string) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {i18n.t('buttons:download')}
        </a>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'url',
      render: (_: unknown, record: AdminCertReviewResponse) => (
        <Space size="middle">
          <AdminVerifyCertDetails record={record} onSuccess={onSuccess} />
        </Space>
      ),
    },
  ];
};
