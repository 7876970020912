import { GarmentsPage } from '../pages/garments/GarmentsPage';
import { TitleProp } from '../pages/PagesTypes';
import { RouteProps } from './RouteTypes';

export const routesGarments: RouteProps<TitleProp>[] = [
  {
    path: '/garments',
    title: 'Garments',
    key: 'garments_page',
    page: GarmentsPage,
  },
];
