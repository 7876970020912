import { Input } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { Color } from '../../../elements/base/Color';
import { changePassword } from '../../../utils/backendRequests/auth/changePassword';
import { passwordValid } from '../../../utils/validations/passwordValidator';
import { Modal } from '../../Modal';
import { ChangePasswordModalProps } from '../ModalTypes';

export function ChangePasswordModal({
  isModalOpen,
  setIsModalOpen,
}: ChangePasswordModalProps) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');

  const passwordMatch = newPassword === verifyPassword;

  // Submits a request to change the password, and resets all fields
  // If successful, closes modal
  const handleSubmit = async () => {
    if (!passwordValid(newPassword)) return;
    const passwordChanged = await changePassword({
      currentPassword: currentPassword,
      newPassword: newPassword,
    });

    setCurrentPassword('');
    setNewPassword('');
    setVerifyPassword('');
    if (passwordChanged) {
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={i18n.t('headers:change_password')}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
      cancelButtonProps={{ danger: true }}
      okButtonProps={{
        disabled: !passwordMatch || verifyPassword === '',
      }}
    >
      <span>{i18n.t('headers:current_password')}</span>
      <Input.Password
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <span>{i18n.t('headers:new_password')}</span>
      <Input.Password
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <span>{i18n.t('headers:confirm_new_password')}</span>
      <Input.Password
        value={verifyPassword}
        onChange={(e) => setVerifyPassword(e.target.value)}
      />
      {!passwordMatch && (
        <span style={{ color: Color.Red }}>
          {i18n.t('error_messages:error_password_match')}
        </span>
      )}
    </Modal>
  );
}
