import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Edits an inspiration description.
 * After the inspiration is successfully edited.
 */
export async function editInspiration(
  id: string,
  description: string | null
): Promise<void> {
  const data = { description: description };
  await api({
    method: 'PUT',
    url: `v1/user_upload/${id}`,
    body: data,
  });
  message.success(i18n.t('long_messages:message_inspiration_updated_success'));
}
