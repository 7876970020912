import { Col, Input, Row } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPriceTag } from '../../../utils/backendRequests/prices/createPriceTag';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { RootAuth } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { CreatePriceTagRequest } from '../../prices/PricesTypes';
import { SelectWithSearch } from '../../search/SelectWithSearch';
import { CreateOrEditPricesModalProps } from '../ModalTypes';

export function CreateOrEditPricesModal({
  collectionId,
  fabricId,
  designerId,
  libraryPrice,
  isCreate,
  getPricesList,
  currencyList,
}: CreateOrEditPricesModalProps) {
  const [modal, setModal] = useState<{ visible: boolean }>({ visible: false });
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [pricePerKg, setPricePerKg] = useState<number | null>(null);
  const [pricePerM, setPricePerM] = useState<number | null>(null);
  const [pricePerPiece, setPricePerPiece] = useState<number | null>(null);
  const [pricePerSqm, setPricePerSqm] = useState<number | null>(null);
  const [currencyId, setCurrencyId] = useState<string | null>(null);

  useEffect(() => {
    if (libraryPrice && currencyList) {
      setPricePerKg(libraryPrice.price_per_kg);
      setPricePerM(libraryPrice.price_per_m);
      setPricePerPiece(libraryPrice.price_per_piece);
      setPricePerSqm(libraryPrice.price_per_sqm);

      // Find currency ID from list using the currency name
      const currencyIdFromValue = Object.keys(currencyList).find(
        (key) => currencyList[key] === libraryPrice.currency
      );

      // Set currencyId to found ID or default to the first in the list
      setCurrencyId(
        currencyIdFromValue || Object.keys(currencyList)[0] || null
      );
    }
  }, [libraryPrice, currencyList, designerId]); // Depend on both libraryPrice and currencyList

  const handleCancel = () => {
    setModal({ visible: false });
  };

  const showModal = () => {
    setModal({ visible: true });
  };

  const handleSubmit = async () => {
    if (currencyId) {
      const createPriceTagBody: CreatePriceTagRequest = {
        fabric_id: fabricId,
        currency_id: currencyId,
        price_per_kg: pricePerKg,
        price_per_m: pricePerM,
        price_per_piece: pricePerPiece,
        price_per_sqm: pricePerSqm,
      };
      const success = await createPriceTag(
        createPriceTagBody,
        collectionId,
        designerId
      );
      if (success) {
        getPricesList();
        handleCancel();
      }
    }
  };

  const modalTitle = isCreate
    ? i18n.t('buttons:create_price')
    : i18n.t('buttons:edit_price');

  return (
    <>
      {isCreate ? (
        <a onClick={showModal} role="button">
          {i18n.t('buttons:create_price')}
        </a>
      ) : (
        <a onClick={showModal} role="button">
          {i18n.t('buttons:edit_price')}
        </a>
      )}
      <Modal
        title={modalTitle}
        open={modal.visible}
        onCancel={handleCancel}
        onOk={handleSubmit}
        okButtonProps={{ disabled: !currencyId }}
        cancelButtonProps={{ danger: true }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <label>{parseFabricTitles('price_per_kg', measurementUnit)}</label>
            <Input
              type="number"
              value={pricePerKg ?? ''}
              onChange={(e) => setPricePerKg(Number(e.target.value) || null)}
            />
          </Col>
          <Col span={24}>
            <label>{parseFabricTitles('price_per_m', measurementUnit)}</label>
            <Input
              type="number"
              value={pricePerM ?? ''}
              onChange={(e) => setPricePerM(Number(e.target.value) || null)}
            />
          </Col>
          <Col span={24}>
            <label>
              {parseFabricTitles('price_per_piece', measurementUnit)}
            </label>
            <Input
              type="number"
              value={pricePerPiece ?? ''}
              onChange={(e) => setPricePerPiece(Number(e.target.value) || null)}
            />
          </Col>
          <Col span={24}>
            <label>{parseFabricTitles('price_per_sqm', measurementUnit)}</label>
            <Input
              type="number"
              value={pricePerSqm ?? ''}
              onChange={(e) => setPricePerSqm(Number(e.target.value) || null)}
            />
          </Col>
          <Col span={24}>
            <label>{i18n.t('currency_id:currency_id')}*</label>
            <SelectWithSearch
              value={currencyId}
              onChange={setCurrencyId}
              style={{ width: '100%' }}
              options={Object.entries(currencyList).map(([id, name]) => ({
                value: id,
                label: name,
              }))}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
