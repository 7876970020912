import { Button, Checkbox, Col, Pagination, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ImageSizeContainer,
  PDFFieldsTextContainer,
} from '../../../elements/fabrics/fabricStyledElement';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import {
  CollectionFieldsContainer,
  SelectableFieldContainer,
} from '../../../elements/modals/StyledModal';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { RootAuth, Setter } from '../../../utils/types/Types';
import { formFieldsProp } from '../../form/FormTypes';
import { Modal } from '../../Modal';
import {
  defaultFieldsToShowOptions,
  fieldToShowOptions,
} from '../collection/CreateCollectionModal';

type ExportFabricsPDFModalProps = {
  onCancel: () => void;
  handlePDFExportFabrics: (
    selectedFields: string[],
    onSuccess: () => void
  ) => void;
  includeImages: 'sm' | 'md' | 'lg' | null;
  setIncludeImages: Setter<'sm' | 'md' | 'lg' | null>;
  visible: boolean;
};

const MAX_GROUPS_PER_PAGE = 2; // Maximum of 2 groups per page (except for 'basic')

export function ExportFabricsPDFModal({
  onCancel = () => {},
  handlePDFExportFabrics,
  includeImages,
  setIncludeImages,
  visible,
}: ExportFabricsPDFModalProps) {
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectAllMode, setSelectAllMode] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1); // For pagination
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  // Paginate the combinedFieldOptions
  const combinedFieldOptions = [
    ...defaultFieldsToShowOptions,
    ...fieldToShowOptions,
  ];

  useEffect(() => {
    // Only set selectAllMode based on the selected fields if the modal is visible
    if (!visible) {
      setSelectedFields([]); // Reset the selection when modal closes
      setCurrentPage(1); // Reset pagination when modal is closed
    }
  }, [visible, selectedFields]); // Also trigger when selectedFields changes

  useEffect(() => {
    setSelectAllMode(selectedFields.length === combinedFieldOptions.length);
  }, [selectedFields]);

  const handleFieldChange = (field: string, checked: boolean) => {
    if (checked) {
      setSelectedFields((prev) => [...prev, field]);
    } else {
      setSelectedFields((prev) => prev.filter((item) => item !== field));
    }
  };

  const handleImageSizeChange = (size: 'sm' | 'md' | 'lg') => {
    setIncludeImages((prev) => (prev === size ? null : size));
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSelectAll = () => setSelectedFields(combinedFieldOptions);

  const handleDeselectAll = () => setSelectedFields([]);

  const handleToggleAll = () => {
    if (selectAllMode) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  };

  const onSuccess = () => {
    // Close the modal
    onCancel();
  };

  const handleSubmit = async () => {
    handlePDFExportFabrics(selectedFields, onSuccess);
  };

  const combinedColumns = returnOrderedFields(combinedFieldOptions);

  const groupColumnsByType = (columns: formFieldsProp[]) => {
    return columns.reduce(
      (acc, column) => {
        const group = column.grouping as string;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(column);
        return acc;
      },
      {} as Record<string, formFieldsProp[]>
    );
  };

  const groupedColumns = groupColumnsByType(combinedColumns);

  const getPaginatedColumns = (
    groupedColumns: Record<string, formFieldsProp[]>,
    currentPage: number
  ) => {
    const groups = Object.entries(groupedColumns);

    // Separate basic group from others
    const basicGroup = groups.find(([group]) => group === 'basic');
    const otherGroups = groups.filter(([group]) => group !== 'basic');

    // Adjust pagination based on group types
    const isBasicPage = currentPage === 1 && basicGroup;

    // Calculate start and end indices for pagination
    const startIndex = isBasicPage
      ? 0
      : (currentPage - 2) * MAX_GROUPS_PER_PAGE; // Subtract 2 for other groups
    const endIndex = startIndex + MAX_GROUPS_PER_PAGE;

    // Get the paginated groups from the other groups
    const paginatedOtherGroups = otherGroups
      .slice(startIndex, endIndex)
      .map(([group, columns]) => ({
        group,
        columns,
      }));

    // Combine groups: Add basic group (if it's a basic page) and an empty grouping
    const combinedGroups = isBasicPage
      ? [
          { group: basicGroup[0], columns: basicGroup[1] }, // Add the basic group
        ]
      : paginatedOtherGroups; // If not basic page, just return other groups

    // Calculate total pages
    const totalPages = Math.ceil(
      // Count the groups for pagination
      (basicGroup ? 1 : 0) + Math.ceil(otherGroups.length / MAX_GROUPS_PER_PAGE)
    );

    return { combinedGroups, totalPages }; // Return both combined groups and total pages
  };

  const { combinedGroups, totalPages } = getPaginatedColumns(
    groupedColumns,
    currentPage
  );

  const handleGroupSelectAll = (group: string) => {
    const columns = groupedColumns[group];
    const allFieldNames = columns.map((col) => col.name);
    const allFieldsSelected = allFieldNames.every((name) =>
      selectedFields.includes(name)
    );

    if (allFieldsSelected) {
      // Deselect all fields in the group
      setSelectedFields((prev) =>
        prev.filter((name) => !allFieldNames.includes(name))
      );
    } else {
      // Select all fields in the group
      setSelectedFields((prev) => [
        ...prev,
        ...allFieldNames.filter((name) => !prev.includes(name)),
      ]);
    }
  };

  const renderGroups = combinedGroups.map(({ group, columns }) => {
    // Split the columns into three separate arrays for each column
    const columnCount = 3;
    const columnsPerGroup = Math.ceil(columns.length / columnCount);
    const columnsInGroups = Array.from({ length: columnCount }, (_, i) =>
      columns.slice(i * columnsPerGroup, (i + 1) * columnsPerGroup)
    );

    return (
      <Col span={24} key={group}>
        <Space>
          <h4 style={{ margin: '0 0 0px 0' }}>{i18n.t(`headers:${group}`)}</h4>
          <Checkbox
            checked={columns.every((col) => selectedFields.includes(col.name))}
            onChange={() => handleGroupSelectAll(group)}
          ></Checkbox>
        </Space>
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          {/* Render each group of columns */}
          {columnsInGroups.map((colGroup, colIndex) => (
            <Col
              key={`group-col-${group}-${colIndex}`}
              span={Math.floor(24 / columnCount)} // Each column takes up 1/3 of the width
            >
              {colGroup.map((col, index) => (
                <SelectableFieldContainer key={`${group}-${col.name}-${index}`}>
                  <Checkbox
                    checked={selectedFields.includes(col.name) || selectAllMode}
                    onChange={(e) =>
                      handleFieldChange(col.name, e.target.checked)
                    }
                  />
                  <span style={{ marginLeft: '8px' }}>
                    {parseFabricTitles(col.name, measurementUnit)}
                  </span>
                </SelectableFieldContainer>
              ))}
            </Col>
          ))}
        </Row>
      </Col>
    );
  });

  return (
    <Modal
      title={i18n.t('headers:create_pdf')}
      open={visible}
      width={700}
      onCancel={handleCancel}
      footer={
        <>
          <Button onClick={handleToggleAll}>
            {selectAllMode
              ? i18n.t('buttons:deselect_all')
              : i18n.t('buttons:select_all')}
          </Button>
          <Button danger onClick={handleCancel}>
            {i18n.t('buttons:cancel')}
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            {i18n.t('buttons:export')}
          </Button>
        </>
      }
    >
      <Row>
        <Space>{i18n.t('headers:include_images_size_options')}</Space>
      </Row>
      <ImageSizeContainer>
        <Checkbox
          checked={includeImages === 'sm'}
          onChange={() => handleImageSizeChange('sm')}
        >
          {i18n.t('headers:small')}
        </Checkbox>
        <Checkbox
          checked={includeImages === 'md'}
          onChange={() => handleImageSizeChange('md')}
        >
          {i18n.t('headers:medium')}
        </Checkbox>
        <Checkbox
          checked={includeImages === 'lg'}
          onChange={() => handleImageSizeChange('lg')}
        >
          {i18n.t('headers:large')}
        </Checkbox>
      </ImageSizeContainer>
      <PDFFieldsTextContainer>
        {i18n.t('headers:fabric_fields_to_include')}
        <HelpIcon
          title={i18n.t('long_messages:message_fabric_show_fields_pdf_message')}
          margin="0 0px 0 -3px"
        />
      </PDFFieldsTextContainer>
      <Row gutter={[8, 8]}>{renderGroups}</Row>
      <CollectionFieldsContainer>
        <Pagination
          current={currentPage}
          total={totalPages}
          pageSize={1}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: '20px', textAlign: 'center' }}
          showSizeChanger={false}
        />
      </CollectionFieldsContainer>
    </Modal>
  );
}
