import { HomePage } from '../pages/HomePage';
import { TitleProp } from '../pages/PagesTypes';
import { RouteProps } from './RouteTypes';

export const routesHome: RouteProps<TitleProp>[] = [
  {
    path: '/',
    title: 'Home',
    key: 'home_page',
    page: HomePage,
  },
];
