import { Space } from 'antd';
import i18n from 'i18next';
import { CreateOrEditPricesModal } from '../../modal/prices/CreateOrEditPricesModal';
import { CollectionPriceFabric, PriceTag } from '../../prices/PricesTypes';
import { DisplayPrices } from './DisplayPrices';

export const columnsMobile = (
  collectionId: string,
  designerId: string,
  getPricesList: () => Promise<void>,
  currencyList: Record<string, string>
) => [
  {
    dataIndex: 'fabric_name',
    title: i18n.t('column_titles:name'),
  },
  {
    dataIndex: 'library_price',
    title: i18n.t('column_titles:library_price'),
    render: (prices: PriceTag) => <DisplayPrices priceDetails={prices} />,
  },
  {
    dataIndex: 'latest_price_tag',
    title: i18n.t('column_titles:collection_price'),
    render: (prices: PriceTag | null) => (
      <DisplayPrices priceDetails={prices} />
    ),
  },
  {
    dataIndex: 'price_tag_date',
    title: i18n.t('column_titles:updated_at'),
    columnType: 'date',
  },
  {
    dataIndex: 'id',
    title: i18n.t('column_titles:action'),
    render: (_: unknown, fabric: CollectionPriceFabric) => (
      <Space size="middle">
        <CreateOrEditPricesModal
          collectionId={collectionId}
          designerId={designerId}
          fabricId={fabric.fabric_id}
          libraryPrice={fabric.latest_price_tag}
          isCreate={!fabric.latest_price_tag}
          getPricesList={getPricesList}
          currencyList={currencyList}
        />
      </Space>
    ),
  },
];
