import { message } from 'antd';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';
/**
 * Send a Bulk Upload to the bin based on its name.
 */
export async function deleteToBinBulkUpload(
  bulkUploadId: string
): Promise<boolean> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/bulk_upload/${bulkUploadId}/delete_to_bin`,
      showErrMsg: true,
    }).then(() => {
      message.success(
        i18n.t('long_messages:message_bulk_upload_deleted_success')
      );
      return true;
    });
  } catch (error) {
    return false;
  }
}
