import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import {
  NoUploadPDFIcon,
  UploadPDFIcon,
} from '../../elements/icons/StyledIcons';
import {
  MoodboardButtonsContainer,
  MoodboardNoUploadRow,
  MoodboardPDFContainer,
  MoodboardUploadContainer,
  MoodboardUploadRow,
  UploadTextContainer,
} from '../../elements/modals/StyledModal';
import i18n from '../../plugins/i18n';
import { getMoodboard } from '../../utils/backendRequests/moodboard/getMoodboard';
import { DeleteMoodboardModal } from '../modal/moodboard/DeleteMoodboardModal';
import { SearchMoodboardModal } from '../modal/moodboard/SearchMoodboardModal';
import { UpdateMoodboardModal } from '../modal/moodboard/UpdateMoodboardModal';
import { UploadMoodboardModal } from '../modal/moodboard/UploadMoodboardModal';
import { DisplayPDF } from './DisplayPDF';
import { MoodboardProps } from './MoodboardTypes';

export function Moodboard({ id, pageType, canEdit }: MoodboardProps) {
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isCropModal, setIsCropModal] = useState(false);
  const [moodboardData, setMoodboardData] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const getMoodboardData = async () => {
    const moodboardPDF = await getMoodboard(id, pageType);
    setMoodboardData(moodboardPDF);
  };

  useEffect(() => {
    getMoodboardData();
  }, []);

  const handleCancelUpload = () => {
    // Also clear currently selected PDF from file explorer
    setUploadModalVisible(false);
  };

  const handleCancelUpdate = () => {
    // Also clear currently selected PDF from file explorer
    setIsUpdateModal(false);
  };

  const openUploadModal = () => {
    setUploadModalVisible(true);
  };

  return (
    <>
      <MoodboardButtonsContainer>
        <Row justify="start" gutter={[16, 16]}>
          <Col>
            <ButtonBasic
              disabled={moodboardData === null}
              onClick={() => setIsCropModal(true)}
            >
              {i18n.t('buttons:image_search')}
            </ButtonBasic>
          </Col>
          {moodboardData ? (
            <Col>
              <ButtonBasic
                rel="noopener noreferrer"
                target="_blank"
                href={moodboardData}
              >
                {i18n.t('buttons:download')}
              </ButtonBasic>
            </Col>
          ) : null}
          {canEdit && (
            <>
              <Col>
                <ButtonBasic
                  onClick={() => setIsUpdateModal(true)}
                  disabled={moodboardData === null}
                >
                  {i18n.t('buttons:update')}
                </ButtonBasic>
              </Col>
              <Col>
                <ButtonBasic
                  onClick={() => setIsDeleteModal(true)}
                  danger
                  disabled={moodboardData === null}
                >
                  {i18n.t('buttons:delete')}
                </ButtonBasic>
              </Col>
            </>
          )}
        </Row>
        {moodboardData && (
          <MoodboardPDFContainer justify="center">
            <Col>
              <DisplayPDF url={moodboardData} setImageUrl={setImageUrl} />
            </Col>
          </MoodboardPDFContainer>
        )}
      </MoodboardButtonsContainer>
      {!moodboardData && canEdit && (
        <MoodboardUploadContainer>
          <MoodboardUploadRow
            justify="center"
            align="middle"
            onClick={openUploadModal}
          >
            <Col>
              <UploadPDFIcon />
              <UploadTextContainer>
                {i18n.t('headers:upload_moodboard')}
              </UploadTextContainer>
            </Col>
          </MoodboardUploadRow>
        </MoodboardUploadContainer>
      )}
      {!moodboardData && !canEdit && (
        <MoodboardUploadContainer>
          <MoodboardNoUploadRow justify="center" align="middle">
            <Col>
              <NoUploadPDFIcon />
              <UploadTextContainer>
                {i18n.t('headers:no_shared_moodboard')}
              </UploadTextContainer>
            </Col>
          </MoodboardNoUploadRow>
        </MoodboardUploadContainer>
      )}
      {moodboardData && imageUrl && (
        <SearchMoodboardModal
          isCropModal={isCropModal}
          setIsCropModal={setIsCropModal}
          imageUrl={imageUrl}
        />
      )}
      <UploadMoodboardModal
        id={id}
        pageType={pageType}
        uploadModalVisible={uploadModalVisible}
        handleCancelUpload={handleCancelUpload}
        getMoodboardData={getMoodboardData}
      />
      <UpdateMoodboardModal
        id={id}
        pageType={pageType}
        uploadModalVisible={isUpdateModal}
        handleCancelUpload={handleCancelUpdate}
        getMoodboardData={getMoodboardData}
      />
      <DeleteMoodboardModal
        id={id}
        pageType={pageType}
        getMoodboardData={getMoodboardData}
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
      />
    </>
  );
}
