import { RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
import {
  DisplayPDFContainer,
  PageNumberContainer,
  PDFPageContainer,
  PDFResetButton,
  PDFToolbarButton,
  PDFToolbarContainer,
} from '../../elements/modals/StyledModal';
import i18n from '../../plugins/i18n';
import { RootState } from '../../utils/types/Types';
import { Spinner } from '../Spinner';
import { DisplayPDFProps } from './MoodboardTypes';

// Configured worker to enable PDF rendering, as recommended by react-pdf documentation.
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export function DisplayPDF({ url, setImageUrl }: DisplayPDFProps) {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setLoading(false);
  }

  const onRenderSuccess = () => {
    if (canvasRef.current) {
      const imageUrl = canvasRef.current.toDataURL(); // Get the image URL
      setImageUrl(imageUrl); // Set it via prop
    }
  };

  const nextPage = () => {
    if (pageNumber < (numPages || 1)) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const rotateLeft = () => setRotation((prev) => (prev - 90 + 360) % 360);
  const rotateRight = () => setRotation((prev) => (prev + 90) % 360);
  const resetRotation = () => setRotation(0);

  return (
    <DisplayPDFContainer>
      <PDFToolbarContainer gutter={16} $isMobileFlag={isMobileFlag}>
        <Button onClick={prevPage} disabled={pageNumber <= 1}>
          {i18n.t('buttons:previous')}
        </Button>
        <PageNumberContainer>
          {pageNumber} / {numPages}
        </PageNumberContainer>
        <Button onClick={nextPage} disabled={pageNumber >= (numPages || 1)}>
          {i18n.t('buttons:next')}
        </Button>
        <PDFToolbarButton onClick={rotateLeft}>
          <RotateLeftOutlined />
        </PDFToolbarButton>
        <PDFToolbarButton onClick={rotateRight}>
          <RotateRightOutlined />
        </PDFToolbarButton>
        <PDFResetButton onClick={resetRotation}>
          {i18n.t('buttons:reset')}
        </PDFResetButton>
      </PDFToolbarContainer>
      <PDFPageContainer $isMobileFlag={isMobileFlag}>
        <Spinner spinning={loading}>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={isMobileFlag ? 300 : rotation % 180 === 0 ? 800 : 1200}
              rotate={rotation}
              canvasRef={(ref) => {
                if (ref) canvasRef.current = ref; // Save canvas reference
              }}
              onRenderSuccess={onRenderSuccess}
            />
          </Document>
        </Spinner>
      </PDFPageContainer>
    </DisplayPDFContainer>
  );
}
