import { Input } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { LinkStyledButton } from '../../../elements/buttons/StyledButtons';
import { updateLabel } from '../../../utils/backendRequests/admin/updateLabel';
import { Modal } from '../../Modal';
import { AdminUpdateLabelNameModalProps } from '../ModalTypes';

/**
 * Modal component for updating the machine learning training label
 * name for all contained fabrics.
 */
export function AdminUpdateLabelNameModal({
  labelData,
  getLabels,
}: AdminUpdateLabelNameModalProps) {
  const [isModalOpen, setIsModalOpen] = useState({ visible: false });
  const [labelName, setLabelName] = useState<string>(labelData.name);

  const handleCancel = () => {
    setIsModalOpen({ visible: false });
  };

  const showModal = () => {
    setIsModalOpen({ visible: true });
  };

  const handleSubmit = async () => {
    const updatedFabrics = await updateLabel({
      existingLabelName: labelData.name,
      newLabelName: labelName,
    });

    if (updatedFabrics) {
      getLabels();
      handleCancel();
    }
  };

  return (
    <>
      <LinkStyledButton onClick={showModal} type="link">
        {i18n.t('buttons:edit')}
      </LinkStyledButton>
      <Modal
        title={i18n.t('headers:update_training_label_name')}
        open={isModalOpen.visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true }}
        okButtonProps={{
          disabled: labelName === '',
        }}
      >
        <span>{i18n.t('headers:ml_training_label_name')}:</span>
        <Input
          value={labelName as string}
          onChange={(e) => setLabelName(e.target.value)}
        />
      </Modal>
    </>
  );
}
