import { CreateFabricPage } from '../pages/fabric/CreateFabricPage';
import { EditFabricPage } from '../pages/fabric/EditFabricPage';
import { FabricDetailsPage } from '../pages/fabric/FabricDetailsPage';
import { FabricLibraryDetailsPage } from '../pages/fabric/LibraryDetailsPage';
import { FabricLibraryListPage } from '../pages/fabric/LibraryListPage';
import { TitleProp } from '../pages/PagesTypes';
import { RouteProps } from './RouteTypes';

// TODO: Children of children does not work due to how the routes are being read at the moment
export const routesFabrics: RouteProps<TitleProp>[] = [
  {
    path: '/fabrics',
    title: 'Fabrics',
    key: 'fabrics_page',
    page: FabricLibraryListPage,
    children: [
      {
        path: '/:libraryId',
        title: 'Library Id',
        page: FabricLibraryDetailsPage,
      },
      {
        path: '/:libraryId/create',
        title: 'Create Fabric',
        page: CreateFabricPage,
      },
      {
        path: '/:libraryId/:fabricId',
        title: 'Fabric Details',
        page: FabricDetailsPage,
      },
      {
        path: '/:libraryId/:fabricId/edit',
        title: 'Fabric Edit',
        page: EditFabricPage,
      },
    ],
  },
];
