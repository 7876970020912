import { message } from 'antd';
import i18n from 'i18next';

import { LibraryType } from '../../../components/modal/library/LibraryModalTypes';
import { api } from '../api/api';

/**
 * Create a new Library from name & description.
 *
 * Returns a promise that resolves with the newly created library
 * or null if there was an error.
 */
export async function createFabricLib(
  description: string | null,
  name: string,
  libraryTypes: Record<string, string>,
  libraryType: string
): Promise<LibraryType | null> {
  const libraryTypekey = Object.entries(libraryTypes).find(
    ([, value]) => value === libraryType
  ) || [''];
  const data = { description, name, library_type: Number(libraryTypekey[0]) };
  try {
    const newLibrary: LibraryType = await api({
      method: 'POST',
      url: 'v1/library',
      body: data,
      showErrMsg: true,
      headers: {
        'content-type': 'application/json',
      },
    });
    message.success(i18n.t('long_messages:message_library_created_success'));
    return newLibrary;
  } catch (error) {
    return null;
  }
}
