import i18n from 'i18next';
import { useSelector } from 'react-redux';

import { message } from 'antd';
import { useState } from 'react';
import { exportPDFFabrics } from '../../utils/fabrics/exportFabric';
import { RootAuth } from '../../utils/types/Types';
import { ExportFabricsPDFModal } from '../modal/fabric/ExportFabricsPDFModal';
import { ExportSelectedFabricsModal } from '../modal/fabric/ExportSelectedFabricsModal';
import { ExportTableProps } from './FabricTypes';

export function ExportSelectedRowsButton({
  data,
  selectedRowKeys,
  exportName,
  exportType,
  visible,
  onCancel,
}: ExportTableProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [includeImages, setIncludeImages] = useState<'sm' | 'md' | 'lg' | null>(
    null
  );

  const handlePDFExportFabrics = async (
    selectedFields: string[],
    onSuccess: () => void
  ) => {
    if (selectedFields.length === 0) {
      message.error(i18n.t('error_messages:select_export_fields_error'));
      return;
    }
    const filteredData = selectedRowKeys
      ? selectedRowKeys.map((key) => data[key - 1]).filter(Boolean)
      : data;
    onSuccess();
    await exportPDFFabrics(
      filteredData,
      measurementUnit as string,
      exportName,
      i18n.t('page_titles:fabrics_page'),
      includeImages,
      selectedFields
    );
    onCancel();
  };

  const selectedRowIds = selectedRowKeys
    .filter((rowKey) => data[rowKey - 1] !== undefined)
    .map((rowKey) => data[rowKey - 1]['id']);

  return (
    <>
      {exportType === 'excel' && (
        <ExportSelectedFabricsModal
          fabricIds={selectedRowIds}
          visible={visible}
          onCancel={onCancel}
        />
      )}
      {exportType === 'pdf' && (
        <ExportFabricsPDFModal
          visible={visible}
          onCancel={onCancel}
          handlePDFExportFabrics={handlePDFExportFabrics}
          includeImages={includeImages}
          setIncludeImages={setIncludeImages}
        />
      )}
    </>
  );
}
