import { message } from 'antd';
import i18n from 'i18next';
import { deleteMoodboard } from '../../../utils/backendRequests/moodboard/deleteMoodboard';
import { Modal } from '../../Modal';
import { DeleteMoodboardModalProps } from '../../moodboard/MoodboardTypes';

export function DeleteMoodboardModal({
  id,
  pageType,
  getMoodboardData,
  isDeleteModal,
  setIsDeleteModal,
}: DeleteMoodboardModalProps) {
  const handleSubmit = async () => {
    const successFlag = await deleteMoodboard(id, pageType);
    if (successFlag) {
      message.success(i18n.t('long_message:moodboard_deleted_success'));
      getMoodboardData();
      setIsDeleteModal(false);
    }
  };

  return (
    <Modal
      style={{ marginTop: '10%' }}
      width={400}
      title={i18n.t('headers:confirm_delete_moodboard')}
      open={isDeleteModal}
      onOk={handleSubmit}
      okText={i18n.t('buttons:delete')}
      okButtonProps={{ danger: true }}
      onCancel={() => setIsDeleteModal(false)}
    >
      {i18n.t('long_messages:message_confirm_permanent_moodboard_deletion')}
    </Modal>
  );
}
