import {
  BulkUploadBinBody,
  CertificationBinBody,
  CollectionBinBody,
  FabricBinBody,
  InspirationBinBody,
  LibraryBinBody,
  ProjectBinBody,
} from '../../../components/bin/BinTypes';
import { parseBinBody } from '../../parsers/parseBinBody';
import { DataProp } from '../../parsers/ParserTypes';
import { restoreBulkUpload } from '../bulkUpload/restoreBulkUpload';
import { restoreCertification } from '../certifications/restoreCertification';
import { restoreCollections } from '../collection/restoreCollection';
import { restoreFabric } from '../fabric/restoreFabric';
import { restoreInspiration } from '../inspirations/restoreInspiration';
import { restoreLibrary } from '../library/restoreLibrary';
import { restoreProject } from '../project/restoreProject';

/**
 * Backend request to restore the requested bin resources. body is created by the
 * utils function 'parseBinBody'. If successful return true and display a success message.
 * If an error occurs, return false and display the error message.
 */
export const restoreBin = async (
  type: string,
  binItems: DataProp,
  selectedRowKeys: number[]
) => {
  const restoreBody = parseBinBody(type, selectedRowKeys, binItems);
  let successFlag;
  switch (type) {
    case 'inspirations':
      successFlag = await restoreInspiration(restoreBody as InspirationBinBody);
      break;
    case 'fabrics':
      successFlag = await restoreFabric(restoreBody as FabricBinBody);
      break;
    case 'libraries':
      successFlag = await restoreLibrary(restoreBody as LibraryBinBody);
      break;
    case 'collections':
      successFlag = await restoreCollections(restoreBody as CollectionBinBody);
      break;
    case 'certifications':
      successFlag = await restoreCertification(
        restoreBody as CertificationBinBody
      );
      break;
    case 'bulkUploads':
      successFlag = await restoreBulkUpload(restoreBody as BulkUploadBinBody);
      break;
    case 'projects':
      successFlag = await restoreProject(restoreBody as ProjectBinBody);
      break;
    default:
      successFlag = false;
  }
  return successFlag;
};
