import {
  DownOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Col, Dropdown, Row, Space, Tabs } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { Color } from '../../elements/base/Color';

import { TabTitle } from '../../components/TabTitle';
import { ExportSelectedRowsButton } from '../../components/fabric/ExportSelectedRowsButton';
import { FabricExportType } from '../../components/fabric/FabricTypes';
import {
  BackendFilterProps,
  TransformedFilterProps,
} from '../../components/filter/FilterTypes';
import { FabricFilterDrawer } from '../../components/filter/fabric/FabricFilterDrawer';
import { FabricSwitchToggle } from '../../components/gallery/FabricSwitchToggle';
import { GalleryView } from '../../components/gallery/GalleryView';
import { CopyToCollectionModal } from '../../components/modal/collection/CopyToCollectionModal';
import { SelectFabricColumns } from '../../components/modal/fabric/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../components/modal/fabric/SelectMobileFabricColumns';
import { CreateFabricsHangerRequest } from '../../components/modal/hangers/CreateFabricsHangerRequest';
import { AddToProjectModal } from '../../components/modal/project/AddToProjectModal';
import { Moodboard } from '../../components/moodboard/Moodboard';
import { PricesTab } from '../../components/prices/PricesTab';
import { intialDataProp } from '../../components/search/SearchTypes';
import { CompareButton } from '../../components/table/CompareButton';
import { FabricTableGeneric } from '../../components/table/FabricTableGeneric';
import { defaultPageSize } from '../../components/table/TableGeneric';
import { columnFields } from '../../components/table/TableTypes';
import { getSelectedColumns } from '../../components/table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../components/table/fabricTable/ColumnsMobile';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import {
  ButtonBasic,
  DropdownButton,
  SelectFabricsButton,
} from '../../elements/buttons/StyledButtons';
import { ToggleButtonsContainer } from '../../elements/fabrics/fabricStyledElement';
import { MainLayout } from '../../layouts/Main';
import { deleteFabricsFromCollection } from '../../utils/backendRequests/collection/deleteFabrics';
import { getSingleCollection } from '../../utils/backendRequests/collection/getSingleCollection';
import { fabricPropertyArray } from '../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../utils/fabrics/parseFabricValues';
import { record, RootAuth, RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function CollectionDetailsPage({ title }: TitleProp) {
  const { id } = useParams(); // collection ID received from the router
  const [data, setData] = useState<record[]>([]);
  const [translatedData, setTranslatedData] = useState<record[]>([]);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [exportType, setExportType] = useState<FabricExportType>(null);
  const [exportModal, setExportModal] = useState(false);
  const [collectionModal, setCollectionModal] = useState({ visible: false });
  const [projectModal, setProjectModal] = useState({ visible: false });
  const [collectionName, setCollectionName] = useState<string>('');
  const [columnModal, setColumnModal] = useState({ visible: false });
  const [requestHangerModal, setRequestHangerModal] = useState({
    visible: false,
  });
  const [isSelectAll, setIsSelectAll] = useState(false); // Track select/deselect state
  const [fabricViewToggle, setFabricViewToggle] = useState<boolean>(() => {
    // Check if the value is already stored in localStorage
    const savedState = localStorage.getItem('fabricViewToggle');
    return savedState ? JSON.parse(savedState) : false; // Default to false if not found
  });
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  // Represents the range of filterable fields from the backend
  const [filterableFields, setFilterableFields] = useState<BackendFilterProps>(
    {}
  );
  // Represents the selected filter values to be sent to the backend
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);
  // Ids of selected fabrics according to the filtered selection
  const selectedIds = useMemo(() => {
    if (data.length === 0) {
      return [];
    } else {
      return selectedKeys
        .filter((rowKey) => data[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => data[(rowKey as number) - 1]['id']);
    }
  }, [data, selectedKeys, fabricViewToggle]);

  const toggleHangerRequestModal = () => {
    setRequestHangerModal({ visible: false });
  };

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'collection',
          measurementUnit,
          selectableColumnNames,
          role,
          account
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'collection',
          measurementUnit,
          role,
          selectableColumnNames,
          account
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames]);

  async function getFabricsFromCollection() {
    const res = await getSingleCollection(
      id as string,
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setCollectionName(res.name);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    } else {
      setData([]);
      setIsOwner(false);
    }
  }

  async function getFilteredFabricsFromCollection() {
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getSingleCollection(
      id as string,
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setCollectionName(res.name);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    } else {
      setData([]);
      setIsOwner(false);
    }
  }

  // Load data on pagination or sorter change
  useEffect(() => {
    if (data.length > 0) getFabricsFromCollection();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [data]);

  // Get Filtered data when selected filters values change
  useEffect(() => {
    // Check if data has items and filterableFields is not an empty object
    // filterableFields represents the range of filterable fields from the backend
    if (data.length > 0 && Object.keys(filterableFields).length > 0) {
      getFilteredFabricsFromCollection();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedKeys([]);
  }, [data]);

  // Get data on page load or resets data when all data filtered out
  useEffect(() => {
    if (data.length === 0) {
      getFabricsFromCollection();
    }
  }, [filterableColumns, id]);

  // Remove selected fabrics from collection
  const handleRemoveConfirm = async () => {
    const response = await deleteFabricsFromCollection(
      id as string,
      selectedIds
    );

    if (response) {
      setModalVisible(false); // Close the modal after deletion
      setSelectedKeys([]);
      getFabricsFromCollection();
    }
  };

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  const hasSelected = selectedKeys.length > 0;

  const handleToggleChange = (checked: boolean) => {
    setFabricViewToggle(checked);
    // Store the toggle value in localStorage
    localStorage.setItem('fabricViewToggle', JSON.stringify(checked));
  };

  useEffect(() => {
    // Update isSelectAll based on whether all items are selected
    const isAllSelected = selectedKeys.length === translatedData.length;
    setIsSelectAll(isAllSelected);
  }, [fabricViewToggle, selectedKeys, translatedData.length]);

  // Function to handle "Select All" / "Deselect All" toggle
  const handleSelectAllToggle = () => {
    if (isSelectAll) {
      // Deselect all
      setSelectedKeys([]);
    } else {
      // Select all
      // +1 to align with the index from the Ant Design table, which starts from 1
      setSelectedKeys(translatedData.map((_, index) => index + 1));
    }
    setIsSelectAll(!isSelectAll); // Toggle the button state
  };

  const handleCloseExport = () => {
    setExportModal(false);
  };

  // Handle export logic
  const handleExport = (type: string) => {
    if (type === 'pdf') {
      // Add PDF export logic
      setExportType('pdf');
    } else if (type === 'excel') {
      // Add Excel export logic
      setExportType('excel');
    }
    setExportModal(true);
  };

  // Define the menu for the dropdown
  const exportItems = [
    {
      key: 'export-pdf',
      label: (
        <DropdownButton onClick={() => handleExport('pdf')}>
          <Space>
            <FilePdfOutlined />
            {i18n.t('buttons:pdf')}
          </Space>
        </DropdownButton>
      ),
    },
    {
      key: 'export-excel',
      label: (
        <DropdownButton onClick={() => handleExport('excel')}>
          <Space>
            <FileExcelOutlined />
            {i18n.t('buttons:excel')}
          </Space>
        </DropdownButton>
      ),
    },
  ];

  const items = [
    {
      key: 'filter',
      label: (
        <DropdownButton
          onClick={() => setFilterOpen(true)}
          disabled={data.length === 0}
        >
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    ...(fabricViewToggle
      ? [
          {
            key: 'select-all',
            label: (
              <DropdownButton
                disabled={translatedData.length === 0}
                onClick={() => handleSelectAllToggle()}
              >
                {isSelectAll
                  ? i18n.t('headers:deselect_all')
                  : i18n.t('headers:select_all')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    ...(role === 'designer'
      ? [
          {
            key: 'compare',
            label: (
              <CompareButton
                filteredData={data}
                selectedRowKeys={selectedKeys}
                isMobile={isMobileFlag}
              />
            ),
          },
        ]
      : []),
    {
      key: 'select-columns',
      label: (
        <DropdownButton
          onClick={() => setColumnModal({ visible: true })}
          disabled={data.length === 0 || fabricViewToggle}
        >
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    ...(account !== 'viewer' && role === 'supplier'
      ? [
          {
            key: 'add-to-collection',
            label: (
              <DropdownButton
                disabled={!hasSelected}
                onClick={() => setCollectionModal({ visible: true })}
              >
                {i18n.t('buttons:add_to_collection')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    ...(role === 'designer'
      ? [
          {
            key: 'add-to-project',
            label: (
              <DropdownButton
                onClick={() => setProjectModal({ visible: true })}
                disabled={!hasSelected}
              >
                {i18n.t('buttons:add_to_project')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    ...(isOwner && account !== 'viewer'
      ? [
          {
            key: 'remove',
            label: (
              <DropdownButton
                onClick={() => setModalVisible(true)}
                disabled={!hasSelected}
                danger
              >
                {i18n.t('buttons:remove')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    // Conditionally include the request hanger button for designers only
    ...(role === 'designer' && account !== 'viewer'
      ? [
          {
            key: 'hanger',
            label: (
              <DropdownButton
                onClick={() => setRequestHangerModal({ visible: true })}
                disabled={data.length === 0 || selectedKeys.length === 0}
              >
                {i18n.t('buttons:request_hanger')}
              </DropdownButton>
            ),
          },
        ]
      : []),
  ];

  const tabs = [
    {
      key: '1',
      label: i18n.t('page_titles:fabrics_page'),
      children: (
        <>
          {role === 'supplier' &&
            i18n.t('page_subtitles:collection_details_sup_sub')}

          {role === 'supplier' && (
            <CopyToCollectionModal
              fabricIds={selectedIds}
              modal={collectionModal}
              setModal={setCollectionModal}
            />
          )}
          {role === 'designer' && (
            <AddToProjectModal
              modal={projectModal}
              setModal={setProjectModal}
              fabricIds={selectedIds}
            />
          )}
          <CreateFabricsHangerRequest
            visible={requestHangerModal.visible}
            onCancel={toggleHangerRequestModal}
            fabricIds={selectedIds}
          />
          {isMobileFlag ? (
            <SelectMobileFabricColumns
              modal={columnModal}
              setModal={setColumnModal}
              setSelectedCol={setColumns}
              pageType={'collection'}
              selectableColumnNames={selectableColumnNames}
            />
          ) : (
            <SelectFabricColumns
              modal={columnModal}
              setModal={setColumnModal}
              setSelectedCol={setColumns}
              pageType={'collection'}
              selectableColumnNames={selectableColumnNames}
            />
          )}
          <Modal
            title={i18n.t('headers:remove_fabrics')}
            open={modalVisible}
            onOk={handleRemoveConfirm}
            onCancel={() => setModalVisible(false)}
            okText={i18n.t('buttons:yes')}
            cancelText={i18n.t('buttons:no')}
            okButtonProps={{
              style: { backgroundColor: Color.Red },
            }}
          >
            <p>
              {i18n.t('long_messages:message_remove_fabrics_from_collection')}
            </p>
          </Modal>
          <Row
            justify="space-between"
            gutter={[16, 16]}
            style={{ paddingTop: '20px' }}
          >
            <Col span={isMobileFlag ? 8 : undefined}>
              {isMobileFlag ? (
                <Space>
                  <Dropdown menu={{ items }}>
                    <ButtonBasic>
                      <Space>
                        {i18n.t('buttons:Options')}
                        <DownOutlined />
                      </Space>
                    </ButtonBasic>
                  </Dropdown>
                  {role === 'designer' && (
                    <Dropdown
                      menu={{ items: exportItems }}
                      trigger={['click']}
                      disabled={!hasSelected}
                    >
                      <ButtonBasic>
                        <Space>
                          {i18n.t('buttons:export')}
                          <DownOutlined />
                        </Space>
                      </ButtonBasic>
                    </Dropdown>
                  )}
                </Space>
              ) : (
                <Space direction="horizontal" size="small">
                  <ButtonBasic
                    onClick={() => setFilterOpen(true)}
                    disabled={
                      data.length === 0 &&
                      Object.keys(filterableColumns).length === 0
                    }
                  >
                    {i18n.t('buttons:filter')}
                  </ButtonBasic>
                  {role === 'designer' && (
                    <CompareButton
                      filteredData={data}
                      selectedRowKeys={selectedKeys}
                    />
                  )}
                  <ButtonBasic
                    onClick={() => setColumnModal({ visible: true })}
                    disabled={data.length === 0 || fabricViewToggle}
                  >
                    {i18n.t('buttons:select_columns')}
                  </ButtonBasic>
                  {role === 'designer' && account !== 'viewer' && (
                    <ButtonBasic
                      onClick={() => setRequestHangerModal({ visible: true })}
                      disabled={data.length === 0 || selectedKeys.length === 0}
                    >
                      {i18n.t('buttons:request_hanger')}
                    </ButtonBasic>
                  )}
                  {role === 'designer' && (
                    <Dropdown
                      menu={{ items: exportItems }}
                      trigger={['click']}
                      disabled={!hasSelected}
                    >
                      <ButtonBasic>
                        <Space>
                          {i18n.t('buttons:export')}
                          <DownOutlined />
                        </Space>
                      </ButtonBasic>
                    </Dropdown>
                  )}
                  {account !== 'viewer' && role === 'supplier' && (
                    <ButtonBasic
                      block
                      disabled={!hasSelected}
                      onClick={() => setCollectionModal({ visible: true })}
                    >
                      {i18n.t('headers:add_to_collection')}
                    </ButtonBasic>
                  )}
                  {role === 'designer' && (
                    <ButtonBasic
                      onClick={() => setProjectModal({ visible: true })}
                      disabled={!hasSelected}
                    >
                      {i18n.t('buttons:add_to_project')}
                    </ButtonBasic>
                  )}
                  {isOwner && account !== 'viewer' && (
                    <ButtonBasic
                      disabled={!hasSelected}
                      danger
                      onClick={() => setModalVisible(true)}
                    >
                      {i18n.t('buttons:remove')}
                    </ButtonBasic>
                  )}
                </Space>
              )}
            </Col>
            <ToggleButtonsContainer>
              <Row align="middle" gutter={8}>
                {fabricViewToggle && !isMobileFlag && (
                  <Col>
                    <SelectFabricsButton
                      onClick={() => handleSelectAllToggle()}
                      disabled={translatedData.length === 0}
                    >
                      {isSelectAll
                        ? i18n.t('headers:deselect_all')
                        : i18n.t('headers:select_all')}
                    </SelectFabricsButton>
                  </Col>
                )}
                <Col>
                  <FabricSwitchToggle
                    fabricViewToggle={fabricViewToggle}
                    handleToggleChange={handleToggleChange}
                  />
                </Col>
              </Row>
            </ToggleButtonsContainer>
          </Row>
          <Row>
            <ExportSelectedRowsButton
              visible={exportModal}
              onCancel={handleCloseExport}
              data={translatedData}
              selectedRowKeys={selectedKeys}
              exportName={collectionName}
              exportType={exportType}
            />
            <FabricFilterDrawer
              filterOpen={filterOpen}
              closeFilter={() => setFilterOpen(false)}
              setFilterableColumns={setFilterableColumns}
              filterableFields={filterableFields}
            />
            {fabricViewToggle ? (
              <GalleryView
                fabrics={translatedData}
                tableState={tableState}
                setTableState={setTableState}
                isMobile={isMobileFlag}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                pageType={'collection'}
              />
            ) : (
              <FabricTableGeneric
                rowSelection={rowSelection}
                columns={columns}
                data={translatedData}
                tableState={tableState}
                setTableState={setTableState}
                isBackendPagination={true}
                sortableColumns={sortableColumnNames}
              />
            )}
          </Row>
        </>
      ),
    },
    {
      key: '2',
      label: i18n.t('page_titles:moodboard'),
      children: (
        <>
          {i18n.t('page_subtitles:collection_moodboard_sub')}
          <Row>
            <Moodboard
              id={id as string}
              pageType="collection"
              canEdit={account !== 'viewer' && isOwner}
            />
          </Row>
        </>
      ),
    },
    ...(role === 'supplier'
      ? [
          {
            key: '3',
            label: i18n.t('page_titles:prices'),
            children: (
              <>
                {i18n.t('page_subtitles:collection_prices_sub')}
                <Row>
                  <PricesTab collectionId={id as string} />
                </Row>
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <MainLayout>
      <HeaderTitle
        title={`${i18n.t(
          'page_titles:collection_details_page'
        )} "${collectionName}"`}
      />
      <TabTitle title={title} id={id} />
      <Tabs defaultActiveKey="1" items={tabs} />
    </MainLayout>
  );
}
