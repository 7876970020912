import { Carousel } from 'antd';
import styled from 'styled-components';
import images from '../../../assets/images/vizoo/vizooImages';
import {
  FabricModelContainer,
  ModelContainer,
  ModelImage,
  ModelText,
  StyledLink,
} from '../../../elements/3dModel/Styled3DModel';
import { Color } from '../../../elements/base/Color';
import i18n from '../../../plugins/i18n';
import { Fabric3DModelProps } from '../FabricTypes';

const StyledCarousel = styled(Carousel)`
  .slick-next,
  .slick-prev {
    color: ${Color.Logo} !important;
    opacity: 0.8 !important;
    background: none !important;
  }

  .slick-next::before,
  .slick-prev::before {
    display: none !important;
  }
`;

export function Fabric3DModel({ isMobile }: Fabric3DModelProps) {
  return (
    <FabricModelContainer>
      <ModelText $isMobile={isMobile}>{i18n.t('headers:3d_model')}</ModelText>
      <p>
        <StyledLink
          href={i18n.t('page_subtitles:link_url')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18n.t('page_subtitles:link_text')}
        </StyledLink>
        {i18n.t('page_subtitles:subtitle_3d_model')}
      </p>

      <ModelContainer>
        {/* Use the StyledCarousel instead of the default Carousel */}
        <StyledCarousel arrows infinite={false} dotPosition="bottom">
          {images.map((image: string, index: number) => (
            <div key={index}>
              <ModelImage src={image} alt={`Fabric Model ${index + 1}`} />
            </div>
          ))}
        </StyledCarousel>
      </ModelContainer>
    </FabricModelContainer>
  );
}
