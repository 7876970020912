import { AllFabricsPage } from '../pages/fabric/AllFabricsPage';
import { EditFabricPage } from '../pages/fabric/EditFabricPage';
import { FabricDetailsPage } from '../pages/fabric/FabricDetailsPage';
import { TitleProp } from '../pages/PagesTypes';
import { RouteProps } from './RouteTypes';

export const allFabrics: RouteProps<TitleProp>[] = [
  {
    path: '/all_fabrics',
    title: 'All Fabrics',
    key: 'all_fabrics_page',
    page: AllFabricsPage,
    children: [
      {
        /**
         * This page is to display the fabric details obtained from all fabrics
         */
        path: '/:fabricId',
        title: 'Fabric Details',
        page: FabricDetailsPage,
      },
      {
        /**
         * This page is to edit the fabric details obtained from all fabrics
         */
        path: '/:fabricId/edit',
        title: 'Fabric Id',
        page: EditFabricPage,
      },
    ],
  },
];
