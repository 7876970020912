import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Pagination,
  Row,
  Switch,
} from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { SaveFabricButton } from '../../elements/buttons/StyledButtons';
import {
  FabricFormPaginationContainer,
  FormTitleFieldsText,
} from '../../elements/fabrics/fabricStyledElement';
import { getEditOptions } from '../../utils/backendRequests/list/lists';
import { compareAndReturnDifferences } from '../../utils/fabrics/populateFabricValues';
import { sortDropdownOptionsByTranslation } from '../../utils/parsers/sortByTranslation';
import { RootAuth, unitType } from '../../utils/types/Types';
import { FabricFormSubmitProps } from '../fabric/FabricTypes';
import { initialFabricFormFields } from '../fabric/formFields';
import { PopulateFabricFieldsModal } from '../modal/fabric/PopulateFabricFieldsModal';
import { SelectWithSearch } from '../search/SelectWithSearch';
import {
  FabricFieldProp,
  FabricFormItemsProps,
  formFieldsProp,
} from './FormTypes';

const { TextArea } = Input;

const GROUP_PAGES = [
  ['basic', 'inventory'], // Page 1
  ['technical'], // Page 2
  ['aesthetics', 'compliance'], // Page 3
  ['usage', 'physical'], // Page 4
];

/**
 * Renders a complete form to create or edit fabric data.
 */
export function FabricForm({
  formFields,
  propsWithDropDownObj,
  setPropsWithDropDownObj,
  formRules = {},
  parseFieldTitle,
  filterFields,
  handleSubmit,
  submitTextKey,
  editFormValues,
  isNarrowLayout,
}: FabricFormItemsProps) {
  const [form] = Form.useForm();
  const [savedValues, setSavedValues] = useState({});
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [isPopFabricModalOpen, setIsPopFabricModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<FabricFormSubmitProps | null>(
    null
  );
  const [isPopulatedData, setIsPopulatedData] = useState<Array<{
    field: string;
    original: unknown;
    updated: unknown;
  }> | null>(null);
  const initialFormFields = useRef(initialFabricFormFields());
  const [currentPage, setCurrentPage] = useState(1);

  // Handle page change with pagination
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    if (isNarrowLayout) window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // Set initial values
    if (editFormValues) {
      // Edit Form Values
      form.setFieldsValue(editFormValues);
    } else {
      // Create Form
      form.setFieldsValue(initialFormFields.current);
    }
  }, [initialFormFields, form, editFormValues]);

  // Group columns by their grouping field
  const groupColumnsByType = (columns: formFieldsProp[]) => {
    return columns.reduce(
      (acc, column) => {
        const group = column.grouping as string;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(column);
        return acc;
      },
      {} as Record<string, formFieldsProp[]>
    );
  };

  // Create grouped form fields
  const groupedFormFields = useMemo(
    () => groupColumnsByType(formFields),
    [formFields]
  );

  // Sorts the dropdown options according to translation output
  const sortedPropsWithDropDownObj = useMemo(() => {
    return sortDropdownOptionsByTranslation(propsWithDropDownObj);
  }, [propsWithDropDownObj]);

  useEffect(() => {
    async function _fetchDropDownOptions() {
      const fetchOptionPromise = formFields
        .filter(
          (field) =>
            typeof field.fieldType === 'string' &&
            field.fieldType.includes('Dropdown')
        )
        .map((field) => getEditOptions(field, setPropsWithDropDownObj));

      await Promise.all(fetchOptionPromise).catch((err) => {
        message.error(err.message);
      });
    }

    _fetchDropDownOptions();
  }, [formFields, setPropsWithDropDownObj]);

  const handleFormChange = (changedValues: FabricFieldProp) => {
    // Save current values when form changes
    setSavedValues((prev) => ({ ...prev, ...changedValues }));
  };

  const handleFormSubmit = async () => {
    const filledValues = form.getFieldsValue(true);
    // Map over formFields to ensure all fields are included
    const allValues = formFields.reduce(
      (acc, field) => {
        const fieldName = field.name as keyof FabricFormSubmitProps;
        acc[fieldName] = Object.prototype.hasOwnProperty.call(
          filledValues,
          fieldName
        )
          ? filledValues[fieldName] === undefined
            ? '' // Set to empty string if the value is undefined
            : filledValues[fieldName]
          : ''; // Set to empty string if the field does not exist
        return acc;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as Record<keyof FabricFormSubmitProps, any> // Assert accumulator type here
    );

    setFormValues(allValues);
    const populatedData = await compareAndReturnDifferences(
      allValues,
      measurementUnit as unitType
    );
    setIsPopulatedData(populatedData);
    if (populatedData === null) {
      //Already autofilled review in process
      handleSubmit(allValues);
      return;
    }
    setIsPopFabricModalOpen(true);
  };

  useEffect(() => {
    // Reset fields if they are not in filterFields but maintain the values that are not affected
    const valuesToKeep = Object.entries(savedValues).reduce(
      (acc, [key, value]) => {
        const fieldExists = filterFields?.some((f) => f.name === key);
        if (fieldExists) {
          (acc as Record<string, typeof value>)[key] = value; // Keep the value
        }
        return acc;
      },
      {}
    );

    // Reset the form with the kept values
    form.setFieldsValue(valuesToKeep);
  }, [filterFields, savedValues, form]);

  const renderFormComponent = (
    field: formFieldsProp,
    additionalProps: Record<string, unknown>
  ) => {
    switch (field.fieldType) {
      case 'stringArea':
        return (
          <TextArea
            {...additionalProps}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        );
      case 'singleDropdown':
      case 'multiDropdown':
        return (
          <SelectWithSearch
            {...additionalProps}
            options={sortedPropsWithDropDownObj[field.name] || []}
            isTranslatable={field.isTranslatable}
            mode={field.fieldType === 'multiDropdown' ? 'multiple' : undefined}
          />
        );
      case 'number':
        return <InputNumber {...additionalProps} style={{ width: '100%' }} />;
      case 'numberInteger':
        return (
          <InputNumber
            {...additionalProps}
            style={{ width: '100%' }}
            precision={0}
          />
        );
      case 'boolean':
        return <Switch {...additionalProps} />;
      case 'date':
        return <DatePicker {...additionalProps} style={{ width: '100%' }} />;
      default:
        return <Input {...additionalProps} />;
    }
  };

  return (
    <>
      {isPopFabricModalOpen && (
        <PopulateFabricFieldsModal
          isVisible={isPopFabricModalOpen}
          onCancel={setIsPopFabricModalOpen}
          formValues={formValues}
          handleSubmit={handleSubmit}
          populatedData={isPopulatedData}
          measurementUnit={measurementUnit as string}
        />
      )}
      <Form
        form={form}
        onFinish={handleFormSubmit}
        onValuesChange={handleFormChange}
      >
        {Object.entries(groupedFormFields).map(([group, fields]) => {
          // Check if this group should be displayed based on pagination
          if (!GROUP_PAGES[currentPage - 1].includes(group)) {
            return null; // Skip rendering this group if it's not part of the current page
          }

          return (
            <div key={group}>
              <FormTitleFieldsText>
                {i18n.t(`headers:${group}`)}
              </FormTitleFieldsText>
              {fields
                .filter(
                  (field) =>
                    !filterFields ||
                    filterFields.some((f) => f.name === field.name)
                ) // Filter fields based on filterFields
                .map((field, _, allFields) => {
                  if (field.name.endsWith('_percentage')) {
                    return null; // Skip rendering the percentage fields in the UI
                  }
                  // Check if the field is one of the composition fields
                  if (
                    field.name.startsWith('composition') &&
                    field.name.endsWith('_id')
                  ) {
                    // Find the corresponding percentage field
                    const percentageField = allFields.find(
                      (f) => f.name === field.name.replace('_id', '_percentage')
                    );

                    if (percentageField) {
                      // Skip rendering the percentage field later
                      allFields = allFields.filter(
                        (f) => f.name !== percentageField.name
                      );

                      return (
                        <Form.Item key={`${field.name}_row`}>
                          <Row gutter={16}>
                            <Col span={16}>
                              <Form.Item
                                label={parseFieldTitle(field.name)}
                                name={field.name}
                                style={{ marginBottom: 0 }}
                              >
                                {renderFormComponent(field, {
                                  placeholder: `${parseFieldTitle(field.name)}`,
                                })}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                label="%"
                                name={percentageField.name}
                                style={{ marginBottom: 0 }}
                              >
                                {renderFormComponent(percentageField, {
                                  placeholder: `%`,
                                })}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      );
                    }
                  }
                  // Default rendering for other fields
                  const additionalProps: Record<string, unknown> = {
                    placeholder: `${parseFieldTitle(field.name)}`,
                  };
                  return (
                    <Form.Item
                      label={parseFieldTitle(field.name)}
                      name={field.name}
                      key={field.name}
                      rules={formRules[field.name]}
                      valuePropName={
                        field.fieldType === 'boolean' ? 'checked' : 'value'
                      }
                    >
                      {renderFormComponent(field, additionalProps)}
                    </Form.Item>
                  );
                })}
            </div>
          );
        })}
        {/* Ant Design Pagination */}
        <FabricFormPaginationContainer>
          <Pagination
            current={currentPage}
            total={GROUP_PAGES.length * 2} // Two groups per page
            pageSize={2}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </FabricFormPaginationContainer>
        <Form.Item>
          <SaveFabricButton submitTextKey={submitTextKey} />
        </Form.Item>
      </Form>
    </>
  );
}
