type ColorType = {
  // Primary colors
  P1: string;
  P2: string;
  P3: string;
  P4: string;
  P5: string;
  P6: string;
  P7: string;

  // Secondary colors
  S1: string;
  S2: string;
  S3: string;
  S4: string;
  S5: string;
  S6: string;

  // Grayscale colors
  G1: string;
  G2: string;
  G3: string;
  G4: string;
  G5: string;
  G6: string;
  G7: string;

  // Other colors
  Background: string;
  Black: string;
  Burgundy: string;
  Green: string;
  Logo: string;
  Red: string;
  White: string;
  AntdPrimary: string;
  UploadBlue: string;
  SecondaryMsg: string;
  PrimaryMsg: string;
  DullGrey: string;
};

export const Color: ColorType = {
  // Primary colors
  P1: '#DBE2F9', // lightest
  P2: '#B7C4F4',
  P3: '#92A7EE',
  P4: '#6E89E9',
  P5: '#4A6CE3',
  P6: '#3B56B6',
  P7: '#2C4188', // darkest

  // Secondary colors
  S1: '#D6F7FE',
  S2: '#ADEFFD',
  S3: '#83E6FC',
  S4: '#83E6FC',
  S5: '#25C7EB',
  S6: '#27ABC8',

  // Grayscale colors
  G1: '#D6DBE2',
  G2: '#ADB8C5',
  G3: '#8594A8',
  G4: '#5C718B',
  G5: '#334D6E',
  G6: '#24364D',
  G7: '#1A2737',

  // Other colors
  Background: '#F2F5FA',
  Black: '#000000',
  DullGrey: '#333333',
  Burgundy: '#590004',
  Green: '#09C688',
  Logo: '#FF6F59',
  Red: '#EB5757',
  White: '#FFFFFF',
  AntdPrimary: '#1890FF',
  UploadBlue: '#1677FF',
  SecondaryMsg: '#F1F1F1',
  PrimaryMsg: '#D1E7FF',
};
