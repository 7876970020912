import { LoginPage } from '../pages/auth/login/LoginPage';
import { PasswordResetPage } from '../pages/auth/passwordReset/PasswordResetPage';
import { TwoFactorAuthenticationPage } from '../pages/auth/TwoFactorAuthenticationPage';
import { TitleProp } from '../pages/PagesTypes';
import { RouteProps } from './RouteTypes';

export const routesAuth: RouteProps<TitleProp>[] = [
  {
    path: '/login',
    title: 'Login',
    page: LoginPage,
  },
  {
    path: '/password-reset',
    title: 'Password Reset',
    page: PasswordResetPage,
  },
  {
    path: '/2fa',
    title: 'Two-Factor Authentication',
    page: TwoFactorAuthenticationPage,
  },
];
