import { message } from 'antd';
import { axios } from '../../../plugins/axios';
import i18n from '../../../plugins/i18n';
/**
 * A template to make API calls to axios with. Also includes general error
 * handling.
 *
 * @param {object} - Object used to make the API call using axios
 * @returns {*} - Return of the API call
 */

interface apiProps {
  method: string;
  url: string;
  body?: object;
  headers?: object;
  params?: object;
  showErrMsg?: boolean;
}

export const api = async ({
  method,
  url,
  body,
  headers,
  params,
  showErrMsg = false,
}: apiProps) => {
  return axios
    .request({
      url: `${process.env.REACT_APP_BACKEND_DOBBY}${url}`,
      method,
      data: body,
      headers,
      params,
    })
    .then((r) => r.data)
    .catch((error) => {
      let errorMsg = '';
      switch (error.response?.status) {
        case 408:
          errorMsg = i18n.t('error_messages:request_timeout');
          break;
        case 502:
          errorMsg = i18n.t('error_messages:bad_gateway');
          break;
        case 503:
          errorMsg = i18n.t('error_messages:service_unavailable');
          break;
        case 504:
          errorMsg = i18n.t('error_messages:gateway_timeout');
          break;
        default:
          errorMsg =
            error.response.data?.detail ||
            i18n.t('error_messages:unexpected_error');
      }
      if (showErrMsg) {
        message.error(errorMsg);
      }
      throw new Error(errorMsg);
    });
};
