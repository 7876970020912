import { Space } from 'antd';
import i18n from 'i18next';
import { BulkUpload } from '../../../utils/backendRequests/bulkUpload/BulkUploadTypes';
import BinBulkUploadButton from './BinBulkUploadButton';
export const BulkUploadColumns = (
  account: string | null,
  getBulkUploadList: () => void
) => {
  const commonColumns = [
    {
      dataIndex: 'created_at',
      title: i18n.t('column_titles:created_at'),
      columnType: 'date',
    },
    {
      dataIndex: 'status',
      title: i18n.t('column_titles:status'),
      columnType: 'string',
      render: (status: string) => i18n.t(`status_values:${status}`),
    },
    {
      dataIndex: 'upload_type',
      title: i18n.t('column_titles:upload_type'),
      columnType: 'string',
      // Consider updating the namespace if more upload_type enums are added
      render: (upload_type: string) => i18n.t(`buttons:${upload_type}`),
    },
    {
      dataIndex: 'successful_fabrics_count',
      title: i18n.t('column_titles:fabrics_added'),
      columnType: 'number',
    },
    {
      dataIndex: 'failed_fabrics_count',
      title: i18n.t('column_titles:fabrics_with_errors'),
      columnType: 'number',
    },
    {
      dataIndex: 'input_file_url',
      title: i18n.t('headers:input_file'),
      render: (input_file_url: string) =>
        input_file_url ? (
          <a rel="noopener noreferrer" target="_blank" href={input_file_url}>
            {i18n.t('buttons:download')}
          </a>
        ) : null,
    },
    {
      dataIndex: 'output_log_url',
      title: i18n.t('column_titles:output_log'),
      render: (output_log_url: string) =>
        output_log_url ? (
          <a rel="noopener noreferrer" target="_blank" href={output_log_url}>
            {i18n.t('buttons:download')}
          </a>
        ) : null,
    },
  ];

  const columnsWithActions = [
    ...commonColumns,
    {
      title: i18n.t('column_titles:action'),
      fixed: 'right',
      render: (_: unknown, record: BulkUpload) => {
        return (
          <Space size="middle">
            <BinBulkUploadButton
              bulkUploadId={record.id}
              getBulkUploadList={getBulkUploadList}
            />
          </Space>
        );
      },
    },
  ];
  return account !== 'viewer' ? columnsWithActions : commonColumns;
};
