import { api } from '../api/api';

/**
 * Returns all currency options avaliable
 */
export const getCurrency = async (): Promise<Record<string, string>> => {
  const res: Record<string, string> = await api({
    method: 'GET',
    url: `v1/list/currencies`,
    showErrMsg: true,
  });
  return res;
};
