import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { TableGeneric } from '../../../components/table/TableGeneric';
import { BulkUpload } from '../../../utils/backendRequests/bulkUpload/BulkUploadTypes';
import { RootAuth } from '../../../utils/types/Types';
import { TabTitle } from '../../TabTitle';
import { columnFields } from '../../table/TableTypes';
import { BulkUploadColumns } from './BulkUploadColumns';

interface BulkUploadListProps {
  title: string;
  data: BulkUpload[];
  getBulkUploads: () => void;
}

export function BulkUploadList({
  title,
  data,
  getBulkUploads,
}: BulkUploadListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  useEffect(() => {
    setColumns(BulkUploadColumns(account, getBulkUploads) as columnFields[]);
  }, []);

  return (
    <>
      {<TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
