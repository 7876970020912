import styled from 'styled-components';
import { Color } from '../base/Color';
import { TextB2 as BaseTextB2 } from '../typography/TextB2';
import { TitleH7 as BaseTitleH7 } from '../typography/TitleH7';

export const StyledIframeContainer = styled.div<{ $isMobile: boolean }>`
  display: ${({ $isMobile }) => ($isMobile ? 'flex' : 'block')};
  justify-content: ${({ $isMobile }) => ($isMobile ? 'center' : 'flex-start')};
  width: ${({ $isMobile }) => ($isMobile ? '90%' : 'auto')};
  margin: ${({ $isMobile }) => ($isMobile ? '0 20px auto' : '0')};
`;

export const TitleH7 = styled(BaseTitleH7)<{ $isMobile: boolean }>`
  text-align: ${({ $isMobile }) => ($isMobile ? 'center' : 'left')};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '10px' : '10px')};
  margin-left: ${({ $isMobile }) => ($isMobile ? '0px' : '0')};
`;

export const StyledIframe = styled.iframe<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? '90%' : '1100px')};
  height: ${({ $isMobile }) => ($isMobile ? '400px' : '600px')};
  border: none;
  display: block;
  margin: ${({ $isMobile }) => ($isMobile ? '0 auto' : '0')};
`;

export const StyledTextB2 = styled(BaseTextB2)<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? '70%' : '1100px')};
  margin: ${({ $isMobile }) => ($isMobile ? '10px 65px auto' : '20px 0')};
  text-align: ${({ $isMobile }) => ($isMobile ? 'center' : 'left')};
`;

export const ModelContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const ModelImage = styled.img`
  max-width: 40%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
  margin: 0 auto;
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  color: blue;
`;

export const ModelText = styled(TitleH7)`
  max-width: 150px;
  color: ${Color.Logo} !important;
`;

export const FabricModelContainer = styled.div`
  padding-bottom: 50px;
`;
