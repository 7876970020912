import { message } from 'antd';
import { api } from '../api/api';
import { EditLabelNameProps } from './AdminTypes';

/**
 * Updates Training label name for all Fabrics with that label name
 */
export async function updateLabel({
  existingLabelName,
  newLabelName,
}: EditLabelNameProps): Promise<boolean> {
  try {
    const parsedData = {
      existing_ml_training_label: existingLabelName,
      new_ml_training_label: newLabelName,
    };
    await api({
      method: 'PUT',
      url: 'v1/superuser/training_label',
      body: parsedData,
      showErrMsg: true,
    });
    message.success('Training Label name updated.');
    return true;
  } catch (error) {
    return false;
  }
}
