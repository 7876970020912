export const linksColumns = () => [
  {
    dataIndex: 'supplier_brand',
    title: 'Supplier Brand',
    columnType: 'string',
  },
  {
    dataIndex: 'collection_name',
    title: 'Collection Name',
    columnType: 'string',
  },
  {
    dataIndex: 'shared_email',
    title: 'Shared Email',
    columnType: 'string',
  },
  {
    dataIndex: 'created_at',
    title: 'Created At',
    columnType: 'date',
  },
  {
    dataIndex: 'expiry_datetime',
    title: 'Expiry Date',
    columnType: 'date',
  },
  {
    dataIndex: 'uses',
    title: 'Uses',
    columnType: 'number',
  },
];
