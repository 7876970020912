import { Space } from 'antd';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import { routesNamedDict } from '../../../routes';
import { UserProps } from '../../../utils/backendRequests/user/getCurrentUser';
import { ExportAllFabricsModal } from '../../modal/fabric/ExportAllFabricsModal';
import { EditProjectModalWithLink } from '../../modal/project/EditProjectModalWithLink';
import { ShareProjectModal } from '../../modal/project/ShareProjectModal';
import { EditProjectRecord } from '../TableTypes';
import { BinProjectButton } from './BinProjectButton';
import { CopyProjectToProjectLink } from './CopyProjectToProjectLink';
import { UnshareProjectButton } from './UnshareProjectButton';

export const columnsDesktop = (
  approvedUsers: UserProps[],
  account: string | null,
  getProjectList: () => Promise<void>
) => [
  {
    dataIndex: 'name',
    key: 'name',
    title: i18n.t('common:name'),
    columnType: 'string',
  },
  {
    dataIndex: 'description',
    title: i18n.t('column_titles:description'),
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
  },
  {
    dataIndex: 'is_owner',
    key: 'is_owner',
    title: i18n.t('column_titles:is_owner'),
    columnType: 'boolean',
    render: (is_owner: boolean) =>
      is_owner === true ? i18n.t('common:me') : i18n.t('common:shared'),
  },
  {
    dataIndex: 'fabric_count',
    key: 'fabric_count',
    title: i18n.t('column_titles:fabrics_count'),
    columnType: 'number',
  },
  {
    dataIndex: 'id',
    key: 'id',
    title: i18n.t('column_titles:action'),
    render: (_: string, record: EditProjectRecord) => {
      return (
        <Space size="middle">
          <Link
            to={routesNamedDict.PROJECTS_PROJECT_ID.replace(
              ':projectId',
              record.id
            )}
          >
            {i18n.t('column_titles:view')}
          </Link>
          <ExportAllFabricsModal projectId={record.id} />
          <CopyProjectToProjectLink
            srcProjectId={record.id}
            getNewProjects={getProjectList}
          />
          {record.is_owner && (
            <>
              {account !== 'viewer' && (
                <ShareProjectModal
                  projectId={record.id}
                  approvedUsers={approvedUsers}
                />
              )}
              <EditProjectModalWithLink
                record={record}
                getProjectList={getProjectList}
              />
              <BinProjectButton
                projectId={record.id}
                getProjectList={getProjectList}
              />
            </>
          )}
          {!record.is_owner && account !== 'viewer' && (
            <UnshareProjectButton
              projectId={record.id}
              getProjectList={getProjectList}
            />
          )}
        </Space>
      );
    },
  },
];
