import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Send a fabric library to the bin based on its id.
 */
export async function deleteToBinFabricLib(libraryId: string): Promise<void> {
  await api({
    method: 'PUT',
    url: `v1/library/${libraryId}/delete_to_bin`,
    showErrMsg: true,
  });
  message.success(i18n.t('long_messages:message_library_deleted_success'));
}
