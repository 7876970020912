import { Checkbox, Col, Row, Space, message } from 'antd';
import { Dayjs } from 'dayjs';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AttachmentsFormText,
  FabricFormAttachmentContainer,
  FabricFormUploadContainer,
  FormTitleFieldsText,
  MobileUploadContainer,
  StyledHorizontalSpace,
  StyledVerticalSpace,
  UploadFormText,
} from '../../elements/fabrics/fabricStyledElement';
import { HelpIcon } from '../../elements/icons/StyledIcons';
import { TextB3 } from '../../elements/typography/TextB3';
import {
  CenteredUploadWrapper,
  FormMobileCheckboxWrapper,
  FormWrapper,
  MobileCheckboxContentWrapper,
  MobileCheckboxRow,
  MobileFormWrapper,
} from '../../elements/wrapper/FormWrapper';
import { createFabric } from '../../utils/backendRequests/fabric/createFabric';
import { editAttachment } from '../../utils/backendRequests/fabric/editAttachment';
import { editFabric } from '../../utils/backendRequests/fabric/editFabric';
import { editFabricImage } from '../../utils/backendRequests/fabric/editFabricImage';
import {
  FabricRecordKey,
  formFieldsProp,
} from '../../utils/fabrics/FabricsTypes';
import {
  ParsedFabricFields,
  parseFabric,
} from '../../utils/fabrics/parseFabric';
import { parseFabricTitles } from '../../utils/fabrics/parseFabricFields';
import { nullParser } from '../../utils/parsers/parseNullValues';
import { RootAuth } from '../../utils/types/Types';
import {
  CompositeFieldCheckProps,
  CreateOrEditFabricFormProps,
  FabricFormSubmitProps,
  FabricSellingOption,
} from '../fabric/FabricTypes';
import {
  compositeFabricFieldCheck,
  fabricFieldRules,
} from '../fabric/fabricFormRules';
import {
  formFields,
  getSelectedFormFields,
  sellingMethodOptions,
  updateSellingOptions,
} from '../fabric/formFields';
import { EditFabricImageUpload } from '../upload/EditFabricImageUpload';
import { FileEditUpload } from '../upload/FileEditUpload';
import { FabricForm } from './FabricForm';

/**
 * FabricForm component
 */
export function CreateOrEditFabricForm({
  fabricId,
  fabric,
  isNarrowLayout,
  isCreate,
  libraryId,
}: CreateOrEditFabricFormProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [backImg, setBackImg] = useState<File | null>(null);
  const [backImgUrl, setBackImgUrl] = useState<string | null>(null);
  const [frontImg, setFrontImg] = useState<File | null>(null);
  const [frontImgUrl, setFrontImgUrl] = useState<string | null>(null);
  const [headerImg, setHeaderImg] = useState<File | null>(null);
  const [headerImgUrl, setHeaderImgUrl] = useState<string | null>(null);
  const [macroImg, setMacroImg] = useState<File | null>(null);
  const [macroImgUrl, setMacroImgUrl] = useState<string | null>(null);
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState({});
  const [attachments, setAttachments] = useState<File[] | null>([]);
  const [deleteAttachmentIDs, setDeleteAttachmentIDs] = useState<string[]>([]);
  const [attachmentsFiles, setAttachmentsFiles] = useState<File[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [filteredFields, setFilteredFields] = useState<formFieldsProp[]>();
  const [editFormValues, setEditFormValues] = useState<ParsedFabricFields>();

  // Initialize the selling options
  const [sellingOptions, setSellingOptions] =
    useState<FabricSellingOption[]>(sellingMethodOptions);

  const fabricRulesConst = useMemo(
    () => fabricFieldRules(measurementUnit),
    [measurementUnit]
  );
  const navigate = useNavigate();

  useEffect(() => {
    const showableFields = getSelectedFormFields(sellingOptions);
    setFilteredFields(showableFields);
  }, [sellingOptions]);

  useEffect(() => {
    if (!isCreate && fabric) {
      // Edit form request
      // parse fabric values into form values
      const parsedData = parseFabric(
        propsWithDropDownObj,
        fabric as unknown as FabricRecordKey
      );
      setEditFormValues(parsedData);
      updateSellingOptions(sellingOptions, setSellingOptions, parsedData);
      // Pre-populate images
      setFrontImgUrl(fabric?.image_url_front);
      setBackImgUrl(fabric?.image_url_back);
      setHeaderImgUrl(fabric?.image_url_header);
      setMacroImgUrl(fabric?.image_url_macro);
      setAttachments(fabric?.attachments as typeof attachments);
    }
  }, [fabric, propsWithDropDownObj]);

  const uploadBack = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_back')}
      setImage={setBackImg}
      imageUrl={backImgUrl}
      setImageUrl={setBackImgUrl}
      deleteFieldName={'back'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_back === backImgUrl}
    />
  );
  const uploadFront = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_front')}
      setImage={setFrontImg}
      imageUrl={frontImgUrl}
      setImageUrl={setFrontImgUrl}
      deleteFieldName={'front'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_front === frontImgUrl}
    />
  );
  const uploadHeader = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_header')}
      setImage={setHeaderImg}
      imageUrl={headerImgUrl}
      setImageUrl={setHeaderImgUrl}
      deleteFieldName={'header'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_header === headerImgUrl}
    />
  );
  const uploadMacro = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_macro')}
      setImage={setMacroImg}
      imageUrl={macroImgUrl}
      setImageUrl={setMacroImgUrl}
      deleteFieldName={'macro'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_macro === macroImgUrl}
    />
  );

  const fileUpload = (
    <FileEditUpload
      attachments={attachments}
      setAttachments={setAttachments}
      setDeleteAttachmentIDs={setDeleteAttachmentIDs}
      setAttachmentsFiles={setAttachmentsFiles}
    />
  );

  const handleSubmit = async (fabricData: FabricFormSubmitProps) => {
    if (!fabricData) return;
    let values = fabricData;
    // Parses field values to null if they are empty strings
    values = nullParser(values) as FabricFormSubmitProps;
    // Format creation_date following the format of date from python datetime
    if (values.creation_date) {
      values.creation_date = (values.creation_date as Dayjs).format(
        'YYYY-MM-DD'
      );
    }

    // Replace form field with correct colours field for backend and delete
    // colour_ids intermediary form field
    if ('colour_ids' in values) {
      values.colours = values.colour_ids as string[];
      delete (values as { colour_ids?: string[] }).colour_ids;
    }

    // Check if composite fields are fulfilled
    if (
      !compositeFabricFieldCheck(values as unknown as CompositeFieldCheckProps)
    )
      return;

    if (isCreate && libraryId) {
      // Create Form
      values = { ...values, library_id: libraryId };
      // Create formData, only append non-null images
      const formData: FormData = new FormData();
      if (frontImg) formData.append('image_front', frontImg);
      if (backImg) formData.append('image_back', backImg);
      if (headerImg) formData.append('image_header', headerImg);
      if (macroImg) formData.append('image_macro', macroImg);
      if (attachments && attachments.length > 0) {
        for (const file of attachments) {
          formData.append('attachments', file);
        }
      }
      formData.append('fabric', JSON.stringify(values));
      const success = await createFabric(formData);
      if (success) navigate(-1);
    } else if (!isCreate && fabricId) {
      // Edit Form
      // Create formData, only append required images
      const formData = new FormData();
      if (frontImg) formData.append('new_image_front', frontImg);
      if (backImg) formData.append('new_image_back', backImg);
      if (headerImg) formData.append('new_image_header', headerImg);
      if (macroImg) formData.append('new_image_macro', macroImg);
      // // Create a map to track current images
      // This prevents duplicate entries and ensures only deleted images are appended for removal
      const map: Record<string, File | null> = {
        front: frontImg,
        back: backImg,
        macro: macroImg,
        header: headerImg,
      };

      // Iterate over imagesToDelete array and append image types to delete to formData if they are not in the map
      // This ensures that only images marked for deletion and not currently present are appended for removal
      for (const element of imagesToDelete) {
        // If the image is null in the map, it means it was deleted by the user

        if (map[element] === null)
          formData.append('image_types_to_delete', element);
      }

      const attachmentForm = new FormData();
      deleteAttachmentIDs.forEach((id) => {
        attachmentForm.append('attachments_ids_to_delete', id);
      });
      attachmentsFiles.forEach((file) => {
        attachmentForm.append('new_attachments_files', file);
      });

      try {
        const success = await editFabric(fabricId, values);
        (frontImg ||
          backImg ||
          headerImg ||
          macroImg ||
          imagesToDelete.length !== 0) &&
          (await editFabricImage(fabricId, formData));
        if (deleteAttachmentIDs.length > 0 || attachmentsFiles.length > 0) {
          await editAttachment(fabricId, attachmentForm);
        }
        if (success) {
          message.success(
            i18n.t('long_messages:message_fabric_updated_success')
          );
          navigate(-1);
        }
      } catch (error) {
        if (error instanceof Error) {
          message.error(error.message);
        }
      }
    }
  };

  const parseFabricTitleWithUnit = (name: string) => {
    return parseFabricTitles(name, measurementUnit);
  };

  // Toggle checkbox selection
  const handleCheckboxChange = (label: string) => {
    setSellingOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.label === label
          ? { ...option, isChecked: !option.isChecked }
          : option
      )
    );
  };

  // Render the Create/Edit FabricForm component
  const RenderForm = (
    <FabricForm
      formFields={formFields}
      propsWithDropDownObj={propsWithDropDownObj}
      setPropsWithDropDownObj={setPropsWithDropDownObj}
      formRules={fabricRulesConst}
      parseFieldTitle={parseFabricTitleWithUnit}
      filterFields={filteredFields as formFieldsProp[]}
      handleSubmit={handleSubmit}
      submitTextKey={isCreate ? 'create' : 'save'}
      isNarrowLayout={isNarrowLayout}
      editFormValues={!isCreate ? editFormValues : undefined}
    />
  );

  const tooltipText = i18n.t('long_messages:sell_fabric_by_field_message');

  return (
    <>
      {isNarrowLayout ? (
        <MobileFormWrapper>
          <CenteredUploadWrapper>
            <UploadFormText>
              {i18n.t('fabric_fields:attachments')}
            </UploadFormText>
            {fileUpload}
            <TextB3>{i18n.t('long_messages:upload_file_rule')}</TextB3>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_macro')}
            </UploadFormText>
            <MobileUploadContainer>{uploadMacro}</MobileUploadContainer>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_header')}
            </UploadFormText>
            <MobileUploadContainer>{uploadHeader}</MobileUploadContainer>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_front')}
            </UploadFormText>
            <MobileUploadContainer>{uploadFront}</MobileUploadContainer>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_back')}
            </UploadFormText>
            <MobileUploadContainer>{uploadBack}</MobileUploadContainer>
          </CenteredUploadWrapper>
          <FormMobileCheckboxWrapper>
            <FormTitleFieldsText>
              {i18n.t('headers:sell_fabric')}
              <HelpIcon title={tooltipText} margin="0 3px 0 3px" />
            </FormTitleFieldsText>
            <MobileCheckboxContentWrapper>
              {sellingOptions.map((option) => (
                <MobileCheckboxRow key={option.label}>
                  <Checkbox
                    key={option.label}
                    checked={option.isChecked}
                    onChange={() => handleCheckboxChange(option.label)}
                  >
                    {i18n.t(`headers:${option.label}`)}
                  </Checkbox>
                </MobileCheckboxRow>
              ))}
            </MobileCheckboxContentWrapper>
          </FormMobileCheckboxWrapper>
          {RenderForm}
        </MobileFormWrapper>
      ) : (
        <StyledHorizontalSpace>
          <FabricFormUploadContainer>
            <StyledVerticalSpace>
              <Row gutter={[16, 16]} justify="center">
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:attachments')}
                  </UploadFormText>
                  {fileUpload}
                </Col>
                <FabricFormAttachmentContainer span={12}>
                  <AttachmentsFormText>
                    {i18n.t('long_messages:upload_file_rule')}
                  </AttachmentsFormText>
                </FabricFormAttachmentContainer>
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_macro')}
                  </UploadFormText>
                  {uploadMacro}
                </Col>
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_header')}
                  </UploadFormText>
                  {uploadHeader}
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_front')}
                  </UploadFormText>
                  {uploadFront}
                </Col>
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_back')}
                  </UploadFormText>
                  {uploadBack}
                </Col>
              </Row>
            </StyledVerticalSpace>
          </FabricFormUploadContainer>
          <Col>
            <FormWrapper>
              <>
                <FormTitleFieldsText>
                  {i18n.t('headers:sell_fabric')}
                  <HelpIcon title={tooltipText} margin="0 3px 0 3px" />
                </FormTitleFieldsText>
                <Space direction="horizontal">
                  {sellingOptions.map((option) => (
                    <Checkbox
                      key={option.label}
                      checked={option.isChecked}
                      onChange={() => handleCheckboxChange(option.label)}
                    >
                      {i18n.t(`headers:${option.label}`)}
                    </Checkbox>
                  ))}
                </Space>
              </>
              {RenderForm}
            </FormWrapper>
          </Col>
        </StyledHorizontalSpace>
      )}
    </>
  );
}
