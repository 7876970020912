import { Button, Checkbox, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import i18n from 'i18next';

import {
  BackendFilterProps,
  BooleanCheckboxFilterProps,
  FilterValues,
} from '../FilterTypes';

/**
 * Checkbox filter component for boolean values.
 * Manages filtering based on checkbox selections and updates filter values accordingly.
 * Triggers data filtering on filter value changes.
 */
export function BooleanCheckboxFilter({
  field,
  currentFilterValues,
  setCurrentFilterValues,
  choices,
}: BooleanCheckboxFilterProps) {
  // Map choices (true/false) to their respective labels
  const labels = (
    currentFilterValues[field] as FilterValues[keyof FilterValues]
  ).render;

  // Note: No show_null_rows logic integrated as it is not
  // needed for current filter columns
  const onSelectAll = () => {
    const newFilterValues: FilterValues | BackendFilterProps = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: choices,
      },
    };
    setCurrentFilterValues(newFilterValues);
  };

  const onDeselectAll = () => {
    const newFilterValues: FilterValues | BackendFilterProps = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: [],
      },
    };
    setCurrentFilterValues(newFilterValues);
  };

  const onCheckboxChange = (value: string) => (event: CheckboxChangeEvent) => {
    const isChecked = event.target.checked;
    let newValues = currentFilterValues[field].values.slice();

    if (isChecked && !newValues.includes(value)) {
      newValues.push(value);
    } else if (!isChecked && newValues.includes(value)) {
      newValues = newValues.filter((v: string) => v !== value);
    }

    const newFilterValues: FilterValues | BackendFilterProps = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: newValues,
      },
    };

    setCurrentFilterValues(newFilterValues);
  };

  return choices.length === 0 ? (
    <div>{i18n.t('long_messages:null_data_filter')}</div>
  ) : (
    <>
      <Row>
        {choices.map((choice, index) => (
          <Space key={choice}>
            <Checkbox
              checked={currentFilterValues[field].values.includes(choice)}
              onChange={onCheckboxChange(choice)}
            >
              {i18n.t(labels[index])}
            </Checkbox>
          </Space>
        ))}
      </Row>
      <Row style={{ padding: '10px 0px' }}>
        <Button.Group>
          <Button
            disabled={
              currentFilterValues[field].values.length === choices.length
            }
            onClick={onSelectAll}
          >
            {i18n.t('buttons:select_all')}
          </Button>
          <Button
            disabled={currentFilterValues[field].values.length === 0}
            onClick={onDeselectAll}
          >
            {i18n.t('buttons:deselect_all')}
          </Button>
        </Button.Group>
      </Row>
    </>
  );
}
