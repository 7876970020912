import { Dayjs } from 'dayjs';
import { formFields } from '../../components/fabric/formFields';
import { ColourRecord } from '../../pages/colours/ColoursPage';
import { dayjs } from '../../plugins/dayjs';
import { FabricCert } from '../backendRequests/certifications/CertificationTypes';
import { DropDownProps, FabricRecordKey, formFieldsProp } from './FabricsTypes';

/**
 * Converts dropdown's selected value to its corresponding id.
 */
const _findIdFromMap = (
  propsWithDropDownObj: DropDownProps,
  key: string,
  value: string
) => {
  const possibleValues = propsWithDropDownObj[key];
  const keyValuePair = possibleValues?.find((prop) => prop.label === value);
  return keyValuePair ? keyValuePair.value : '';
};

export interface ParsedFabricFields {
  [key: string]: string | number | Array<string | number> | Dayjs | undefined; // Include Dayjs type
}

/**
 * Converts fabric data from the backend to a format that can be used by the form and later submitted to the backend.
 */
export const parseFabric = (
  propsWithDropDownObj: DropDownProps,
  record: FabricRecordKey
) => {
  const editFormFields: ParsedFabricFields = {};
  const compositionRegex = /^composition(\d+)_id$/;

  formFields.forEach(
    (field: formFieldsProp, i: number, arr: formFieldsProp[]) => {
      const compositionMatch = field.name.match(compositionRegex);

      if (field.name === 'currency_id') {
        // Parses currency_id
        editFormFields[field.name] = _findIdFromMap(
          propsWithDropDownObj,
          'currency_id',
          record?.['currency']
        );
      } else if (field.name === 'construction_id') {
        // Parses construction_id
        editFormFields[field.name] = _findIdFromMap(
          propsWithDropDownObj,
          'construction_id',
          record?.['construction']
        );
      } else if (field.name === 'country_of_origin_id') {
        // Parses country_of_origin_id
        editFormFields[field.name] = _findIdFromMap(
          propsWithDropDownObj,
          'country_of_origin_id',
          record?.['country_of_origin'] as string
        );
      } else if (field.name === 'colour_ids') {
        const colours = record['colours'] as ColourRecord[];
        if (Array.isArray(colours)) {
          editFormFields['colour_ids'] = colours.map((item) => item.id);
        }
      } else if (compositionMatch) {
        // Parses composition_id and composition_percentage
        const compositionNum = parseInt(compositionMatch[1], 10);
        const existingComp = record.compositions?.[compositionNum - 1];
        // Parse composition_id
        editFormFields[field.name] = _findIdFromMap(
          propsWithDropDownObj,
          field.name,
          existingComp?.name
        );
        // Parse the composition_percentage
        editFormFields[arr[i + 1].name] = existingComp?.percentage;
      } else if (
        field.fieldType === 'singleDropdown' &&
        field.name in propsWithDropDownObj
      ) {
        // Parses all other dropdown fields
        editFormFields[field.name] = _findIdFromMap(
          propsWithDropDownObj,
          field.name,
          record?.[field.name] as string
        );
      } else if (
        field.name === 'certifications' &&
        field.name in propsWithDropDownObj
      ) {
        // Parses certifications
        editFormFields[field.name] = (
          record?.[field.name] as unknown as FabricCert[]
        ).map((item: FabricCert) => {
          return _findIdFromMap(propsWithDropDownObj, field.name, item?.name);
        });
      } else if (
        field.fieldType === 'multiDropdown' &&
        field.name in propsWithDropDownObj
      ) {
        editFormFields[field.name] = (record?.[field.name] as string[]).map(
          (item: string) => {
            return _findIdFromMap(propsWithDropDownObj, field.name, item);
          }
        );
      } else if (field.fieldType === 'date' && record?.[field.name]) {
        // Parse fields to dayjs object for antd DatePicker
        editFormFields[field.name] = dayjs(
          record?.[field.name] as string | Date,
          'YYYY-MM-DD'
        );
      } else if (
        // Parses all other fields
        editFormFields[field.name] === undefined
      ) {
        // Set field to empty string if it is undefined or null but preserve 0 values
        if (record?.[field.name] === 0) editFormFields[field.name] = 0;
        else
          editFormFields[field.name] = (record?.[field.name] as string[]) || '';
      }
    }
  );
  return editFormFields;
};

/**
 * Parses the price string to either display both the currency and price field
 * given or just a blank string
 */
export const priceParser = (currency: string, price: number | null): string => {
  return currency && price != null ? `${currency} ${price}` : '';
};

/**
 * Rounds the similarity score to 2 decimal places and returns it as a string
 */
export const similarityScoreParser = (score: number | null): string => {
  return score != null ? score.toFixed(2) : '';
};
