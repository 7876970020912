import { FilterValues } from '../FilterTypes';

export const createInitialFilterValues = (role?: string) => {
  const initialFilterValues: FilterValues = {
    created_at: {
      filter_type: 'date',
      values: [],
      has_null: true,
      required: true,
      grouping: 'other',
    },
    ...(role === 'supplier'
      ? {}
      : {
          owner: {
            filter_type: 'checkbox',
            values: [],
            has_null: true,
            required: true,
            grouping: 'other',
          },
        }),
    fabric_count: {
      filter_type: 'number',
      values: [],
      has_null: true,
      required: true,
      grouping: 'other',
    },
  };
  return initialFilterValues;
};
