import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { SelectWithSearch } from '../../components/search/SelectWithSearch';
import { Color } from '../base/Color';
import { TextB2 } from '../typography/TextB2';
import { TitleH7 } from '../typography/TitleH7';

export const ColorCircle = styled.div<{
  $hexValue: string;
  $tooltipShow: boolean;
}>`
  width: 20px;
  height: 20px;
  background-color: ${({ $hexValue }) => $hexValue};
  border-radius: 50%;
  outline: #d3d3d3 0.5px solid;
  cursor: ${({ $tooltipShow }) => ($tooltipShow ? 'pointer' : 'default')};
`;

export const ColourTableText = styled(TextB2)`
  word-wrap: break-word;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DisplayColourContainer = styled.div<{
  $isTechnical: boolean;
}>`
  margin-top: ${(props) => (props.$isTechnical ? '-6px' : '0px')};
`;

export const DisplayColourRow = styled(Row)<{
  $isTechnical: boolean;
}>`
  margin-bottom: ${(props) => (props.$isTechnical ? '-12px' : '0px')};
  margin-top: ${(props) => (props.$isTechnical ? '-6px' : '0px')};
  justify-content: ${(props) => (props.$isTechnical ? 'start' : 'center')};
`;

export const ColoursDesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  height: 100%;
`;

export const DesktopToolboxContainer = styled.div`
  width: 350px;
  border: 2px solid ${Color.Logo};
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const ColoursMobileContainer = styled.div`
  display: block;
  margin-top: 20px;
  padding: 20px;
`;

export const MobileToolboxContainer = styled.div`
  border: 2px solid ${Color.Logo};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ColourImageContainer = styled.div<{
  $isMobile: boolean;
}>`
  width: 250px;
  height: ${(props) => (props.$isMobile ? '100%' : '300px;')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const ImageContainerCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const StyledColourImage = styled.img<{
  $width: number;
  $height: number;
}>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  object-fit: contain;
`;

interface ImageOverlayContainerProps {
  $selectedHex: string;
  $width: number;
  $height: number;
}

export const ImageOverlayContainer = styled.div<ImageOverlayContainerProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height + 1}px;
  background-color: ${({ $selectedHex }) => $selectedHex};
  opacity: 0.75;
  pointer-events: none;
  transform: translate(-50%, -50%);
`;

export const MobileSelectSearch = styled(SelectWithSearch)`
  width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DesktopSelectSearch = styled(SelectWithSearch)`
  width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ResetColourButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

export const DesktopImageContainerRow = styled(Row)`
  margin-top: -50px;
`;

export const DesktopColourSubContainer = styled.div`
  margin-top: 10px;
  max-width: 80%;
`;

export const MobileColourSubContainer = styled.div`
  margin-top: 10px;
`;

export const ColoursTitleText = styled(TitleH7)`
  max-width: 180px;
  margin-bottom: 0;
  color: ${Color.Logo} !important;
`;

export const FabricColourContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-top: 10px;
`;
