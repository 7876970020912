import { message } from 'antd';
import { InspirationBinBody } from '../../../components/bin/BinTypes';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Backend request to permanently delete selected inspiration from the bin.
 * If successful return true and display a success message.
 * If an error occurs, return false and display the error message.
 */
export const deleteInspiration = async (
  body: InspirationBinBody
): Promise<boolean> => {
  let successFlag = false;
  try {
    const response: MsgProp = await api({
      method: 'DELETE',
      url: 'v1/user_upload',
      body: body,
      showErrMsg: true,
    });
    successFlag = true;
    message.success(response.detail);
  } catch (error) {
    successFlag = false;
  }
  return successFlag;
};
