import { MoodboardPageTypes } from '../../../components/moodboard/MoodboardTypes';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Fetches the moodboard's attachment url based on the given id.
 * Currently pageType is either collection or projects for endpoint call
 */
export async function deleteMoodboard(
  id: string,
  pageType: MoodboardPageTypes
): Promise<boolean> {
  try {
    await api({
      method: 'DELETE',
      url: `v1/${pageType}/${id}/attachment`,
    });
    return true;
  } catch (error) {
    throw new Error(i18n.t('error_messages:error_fabric_attachments_failed'));
  }
}
