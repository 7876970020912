import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../plugins/i18n';
import { SharedDesignerBrandsObject } from '../../utils/backendRequests/collection/CollectionTypes';
import { getSharedDesignerBrands } from '../../utils/backendRequests/collection/getSharedDesignerBrands';
import { getCollectionPrices } from '../../utils/backendRequests/prices/getCollectionPrices';
import { RootAuth } from '../../utils/types/Types';
import { SelectWithSearch } from '../search/SelectWithSearch';
import { KeyValueDesignerBrandObject } from '../table/collectionList/CollectionListTypes';
import { PricesList } from '../table/pricesList/PricesList';
import { defaultPageSize } from '../table/TableGeneric';
import { CollectionPricesResp, PricesTabProps } from './PricesTypes';

export function PricesTab({ collectionId }: PricesTabProps) {
  const [designerId, setDesignerId] = useState<string>();
  const [approvedDesignerBrands, setApprovedDesignerBrands] = useState<
    KeyValueDesignerBrandObject[]
  >([]);
  const [priceData, setPriceData] = useState<CollectionPricesResp>();
  const role = useSelector((state: RootAuth) => state.auth.role);
  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  const getDesignerList = async () => {
    const sharedBrands: SharedDesignerBrandsObject =
      await getSharedDesignerBrands(collectionId);
    if (sharedBrands && Object.keys(sharedBrands).length > 0) {
      const arraySharedDesignerBrands: KeyValueDesignerBrandObject[] =
        Object.entries(sharedBrands).map(([key, value]) => {
          return { key, value };
        });
      setApprovedDesignerBrands(arraySharedDesignerBrands);
      setDesignerId(arraySharedDesignerBrands[0].key);
    }
  };

  useEffect(() => {
    // Only run if user is a supplier
    if (role === 'supplier') getDesignerList();
  }, []);

  const getPricesList = async () => {
    if (designerId) {
      const data = await getCollectionPrices(
        collectionId,
        designerId,
        tableState
      );
      setPriceData(data);
      setTableState((prevState) => ({
        ...prevState,
        total: data.fabrics_count,
      }));
    }
  };

  // Load data on pagination or sorter change
  useEffect(() => {
    if (priceData?.fabrics?.length) {
      getPricesList();
    }
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  useEffect(() => {
    getPricesList();
  }, [designerId]);

  const handleDesignerChange = (value: string) => {
    setDesignerId(value);
  };

  return (
    <div>
      <h3> {i18n.t('headers:select_designer_brand')}: </h3>
      <SelectWithSearch
        value={designerId}
        allowClear={false}
        options={approvedDesignerBrands.map(({ key, value }) => ({
          label: value, // Display the designer name
          value: key, // Use the key as the actual value
        }))}
        onChange={handleDesignerChange} // Update state when user selects
        placeholder={i18n.t('headers:select_designer_placeholder')}
      />
      {priceData && designerId && (
        <PricesList
          data={priceData.fabrics}
          tableState={tableState}
          setTableState={setTableState}
          collectionId={collectionId}
          designerId={designerId}
          getPricesList={getPricesList}
        />
      )}
    </div>
  );
}
