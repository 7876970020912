import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Space } from 'antd';
import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import i18n from '../../../plugins/i18n';
import { RootAuth } from '../../../utils/types/Types';
import { GenericFilterDrawer } from '../../filter/generic/GenericFilterDrawer';
import { createInitialFilterValues } from '../../filter/generic/hangersInitialValues';
import { HangersEditModal } from '../../modal/hangers/HangersEditModal';
import { TabTitle } from '../../TabTitle';
import { TableGeneric } from '../TableGeneric';
import {
  columnFields,
  hangerRequestResp,
  HangersListProps,
} from '../TableTypes';
import { hangersColumns } from './HangersColumns';

export function HangersList({ data, title, getHangerData }: HangersListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [filteredData, setFilteredData] = useState<hangerRequestResp[]>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [editData, setEditData] = useState<hangerRequestResp[]>();

  useEffect(() => {
    setColumns(hangersColumns(account, getHangerData, role) as columnFields[]);
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys as number[]);
    },
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleEditHangers = () => {
    // Get hanger_request_ids based on selectedRowKeys
    const selectedHangers: hangerRequestResp[] = selectedRowKeys.map(
      (key) => filteredData[key - 1]
    ); // Adjust for zero-based index
    setEditData(selectedHangers);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Update filteredData whenever data change
  useEffect(() => {
    if (data && data.length > 0) {
      setFilteredData(data);
    }
  }, [data]);

  return (
    <>
      <Space>
        <ButtonBasic
          onClick={() => setFilterOpen(true)}
          disabled={data.length === 0}
        >
          {i18n.t('buttons:filter')}
        </ButtonBasic>
        {account !== 'viewer' && (
          <ButtonBasic
            disabled={!hasSelected}
            onClick={() => handleEditHangers()}
          >
            {i18n.t('buttons:edit')}
          </ButtonBasic>
        )}
      </Space>
      {editData && (
        <HangersEditModal
          data={editData}
          getHangerData={getHangerData}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      {data[0] && <TabTitle title={title} />}
      <GenericFilterDrawer
        initialData={data}
        setFilteredData={setFilteredData}
        filterOpen={filterOpen}
        closeFilter={() => setFilterOpen(false)}
        createInitialFilterValues={() =>
          createInitialFilterValues(role as string)
        }
      />
      <TableGeneric
        rowSelection={rowSelection}
        columns={columns}
        data={filteredData}
        isBackendPaginated={false}
      />
    </>
  );
}
