import { MoodboardPageTypes } from '../../../components/moodboard/MoodboardTypes';
import { api } from '../api/api';
import { CollectionListItem } from '../collection/CollectionTypes';
import { projectsResponse } from '../project/ProjectTypes';

/**
 * Fetches the moodboard's attachment url based on the given id.
 * Currently pageType is either collection or projects for endpoint call
 */
export async function getMoodboard(
  id: string,
  pageType: MoodboardPageTypes
): Promise<string | null> {
  return await api({
    method: 'GET',
    url: `v1/${pageType}`,
    showErrMsg: true,
  })
    //logic will be updated to call the specific individual project/collection
    // when the backend logic is updated. Fine for now.
    .then((res: CollectionListItem[] | projectsResponse[]) => {
      // Find the attachment data that matches the given id for collection/project
      const moodboardData = res.find((item) => item.id === id);

      // Return the attachment_url if found, otherwise null
      return moodboardData?.attachment_url || null;
    })
    .catch(() => {
      return null;
    });
}
