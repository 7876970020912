import { Space } from 'antd';
import i18n from 'i18next';

import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { EditInspirationModal } from '../../modal/inspirations/EditInspirationModal';
import { InspirationsData } from '../TableTypes';
import { BinInspirationButton } from './BinInspirationButton';

export const columnsDesktop = (getInspirations: () => Promise<void>) => [
  {
    dataIndex: 'image_url',
    key: 'image',
    title: i18n.t('column_titles:image'),
    width: 160,
    render: (text: string) => (
      <FrontImageStyled width={'8vw'} height={'8vw'} src={text} />
    ),
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: i18n.t('column_titles:description'),
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    title: i18n.t('column_titles:uploaded_at'),
    columnType: 'date',
  },
  {
    dataIndex: 'id',
    key: 'id',
    title: i18n.t('column_titles:action'),
    render: (id: string, record: InspirationsData) => (
      <Space size="middle">
        <EditInspirationModal
          inspirationData={record}
          inspirationId={id}
          getInspirationList={getInspirations}
        />
        <BinInspirationButton
          userInspirationId={id}
          getInspirations={getInspirations}
        />
      </Space>
    ),
  },
];
