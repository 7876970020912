import { Input } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { editInspiration } from '../../../utils/backendRequests/inspirations/editInspiration';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { EditInspirationModalProps } from '../../table/TableTypes';

export const EditInspirationModal = ({
  inspirationData,
  inspirationId,
  getInspirationList,
}: EditInspirationModalProps) => {
  const [modal, setModal] = useState({ visible: false });
  const [newDescription, setNewDescription] = useState(
    inspirationData.description
  );
  const [errorDescription, setErrorDescription] = useState(false);

  const handleCancel = () => {
    setModal({ visible: false });
    setNewDescription(inspirationData.description);
  };

  useEffect(() => {
    if (!modal.visible) {
      setNewDescription(inspirationData.description);
      setErrorDescription(false);
    }
  }, [inspirationData]);

  const handleOk = async () => {
    setErrorDescription(false);
    const isDescriptionValid = stringValid(
      newDescription,
      500,
      i18n.t('column_titles:description'),
      true
    );

    if (isDescriptionValid) {
      const descriptionValue = newDescription === '' ? null : newDescription;
      await editInspiration(inspirationId, descriptionValue);
      getInspirationList();
      setModal({ visible: false });
    } else {
      setErrorDescription(true);
    }
  };

  const showModal = () => {
    setModal({ visible: true });
  };

  return (
    <>
      <a onClick={showModal} role="button">
        {i18n.t('buttons:edit')}
      </a>
      <Modal
        title={i18n.t('headers:edit_inspiration')}
        open={modal.visible}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true }}
        onOk={handleOk}
      >
        {`${i18n.t('column_titles:description')}:`}
        <Input
          showCount
          value={newDescription}
          style={{ marginTop: '10px' }}
          onChange={(e) => setNewDescription(e.target.value)}
          status={errorDescription ? 'error' : ''}
        />
      </Modal>
    </>
  );
};
