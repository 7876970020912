import i18n from 'i18next';
import { MoodboardPageTypes } from '../../../components/moodboard/MoodboardTypes';
import { api } from '../api/api';

/**
 * Creates a new moodboard with selected PDF uploaded.
 * Currently pageType is either collection or projects for endpoint call
 */
export async function createMoodboard(
  id: string,
  pageType: MoodboardPageTypes,
  data: FormData
): Promise<boolean> {
  try {
    await api({
      method: 'POST',
      url: `v1/${pageType}/${id}/attachment`,
      body: data,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    return true;
  } catch (error) {
    throw new Error(i18n.t('error_messages:error_fabric_attachments_failed'));
  }
}
