import { useSelector } from 'react-redux';
import { priceParser } from '../../../utils/fabrics/parseFabric';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { RootAuth } from '../../../utils/types/Types';
import { DisplayPricesProps } from '../../prices/PricesTypes';

export const DisplayPrices = ({ priceDetails }: DisplayPricesProps) => {
  if (!priceDetails) return null;
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  return (
    <div>
      <div>
        {priceDetails.price_per_kg != null && (
          <p>
            <strong>
              {/*keep this extra spacing below*/}
              {parseFabricTitles('price_per_kg', measurementUnit)}:{' '}
            </strong>
            {priceParser(
              priceDetails.currency as string,
              priceDetails.price_per_kg
            )}
          </p>
        )}
        {priceDetails.price_per_m != null && (
          <p>
            <strong>
              {/*keep this extra spacing below*/}
              {parseFabricTitles('price_per_m', measurementUnit)}:{' '}
            </strong>
            {priceParser(
              priceDetails.currency as string,
              priceDetails.price_per_m
            )}
          </p>
        )}
        {priceDetails.price_per_piece != null && (
          <p>
            <strong>
              {/*keep this extra spacing below*/}
              {parseFabricTitles('price_per_piece', measurementUnit)}:{' '}
            </strong>
            {priceParser(
              priceDetails.currency as string,
              priceDetails.price_per_piece
            )}
          </p>
        )}
        {priceDetails.price_per_sqm != null && (
          <p>
            <strong>
              {/*keep this extra spacing below*/}
              {parseFabricTitles('price_per_sqm', measurementUnit)}:{' '}
            </strong>
            {priceParser(
              priceDetails.currency as string,
              priceDetails.price_per_sqm
            )}
          </p>
        )}
      </div>
    </div>
  );
};
